<template>
    <div v-if="isVisible" class="popup-overlay" @click="closePopup">
        <div class="popup-content" @click.stop>
            <button class="close-btn" @click="closePopup"><img src='@/assets/images/cross.png' /></button>
            <div class="header-popup">
                <div class="title-code">{{payout.id}}</div>
                

            </div>
            <table class="table left-align">
                <tr>
                    <th>Référence de transaction</th>
                    <td>{{ payout.id }}</td>
                </tr>
                <tr>
                    <th>Date de paiement</th>
                    <td>{{ payout.created }}</td>
                </tr>
                <tr>
                    <th>Montant payé</th>
                    <td>{{ (payout.amount/100).toFixed(2) }}</td>
                </tr>
                <tr>
                    <th>Mode de paiement</th>
                    <td>{{ payout.object }}</td>
                </tr>
                <tr>
                    <th>Devise</th>
                    <td>{{ payout.currency }}</td>
                </tr>
                <tr>
                    <th>Statut du paiement</th>
                    <td> <span class="pastille green"></span> Réussi</td>
                </tr>
               
            </table>
           
        </div>
    </div>
</template>

<script>
import { BButton,BFormInput, BForm, BRow, BOverlay,BFormDatepicker, BCol, BFormGroup,BFormFile, BCard, BFormCheckbox, BFormTextarea,BBadge} from 'bootstrap-vue'

export default {
    name: 'PopupPayout',
    components: {
        BButton,
        BBadge
    },
    props:['payout'],
    data() {
        return {
            isVisible: false
        };
    },
    methods: {
     
        openPopup() {
            this.isVisible = true;
        },
        closePopup() {
            this.isVisible = false;
        },
       
        
    }
};
</script>

<style scoped>
.green-patch {
    background: #E2F7E8;
    color: #29C770;
    padding: 5px 20px;
    border-radius: 10px;
    font-size: 22px;
}
.close-btn img {
    width:34px;
    height:auto;
}
.title-code .cc
{
    cursor: pointer;
    font-size:12px;
}
.title-code {
    text-transform: uppercase;
    display: flex;
    align-items: center;
   
    font-size: 38px;
    padding-left:20px;
}
.header-popup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
}
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    position: relative;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
}
@media screen and (max-width: 768px) {
    .popup-content {
        width: 90%;
        padding-top:30px;
        overflow: auto;
    }
    
    table,tr,
    td {
        overflow: auto;
    }
    .title-code
    {
        overflow: auto;
        font-size:23px;
    }
    .green-patch
    {
        font-size: 14px;
    }
}
</style>