<template>
  <div class="intro-container">
    <div class="logo"><img src="@/assets/images/logo_white.png" /></div>
    <div class="voile" v-if="displayVoile"></div>
    <div class="blur-bg" v-if="animFinished"></div>
    <div class="image-follow-container" :class="{animate:animFinished==true}" @mousemove="handleMouseMove" 
    :style="{
      marginLeft: `-${lastImage.x}px`,
      marginTop: `-${lastImage.y}px`,
     
    }">
    
       
        <div :class="{leaving:animFinished==true}" class="blur-content">Join The Eyewear Revolution.</div>
    
        <div 
          v-for="(photo, index) in photos" 
          :key="photo.id" 
          class="image-follow" 
          :style="{
            left: `${photo.x}px`,
            top: `${photo.y}px`,
            transform: photo.transform_topleft,
          }">
          
          <div class="photo-inner"
          :style="{
    
            backgroundImage: `url(${photo.url})`,
            opacity: photo.opacity,
            transform: photo.transform,
          }"
          ><div class="blur"></div></div>
        </div>


        <div class="button bottom-right" :class="{leaving:animFinished==true}" @click="finishAnimation()">Start Creating</div>
    </div>
  </div>
</template>
  
  <script>
  export default {
    name: "ImageFollowCursor",
    data() {
      return {
        displayVoile:false,
        images: [
          require('@/assets/images/anim/1-min.jpg'),
          require('@/assets/images/anim/2-min.jpg'),
          require('@/assets/images/anim/3-min.jpg'),
          require('@/assets/images/anim/4-min.jpg'),
          require('@/assets/images/anim/5-min.jpg'),
          require('@/assets/images/anim/6-min.jpg'),
          require('@/assets/images/anim/7-min.jpg'),
          require('@/assets/images/anim/8-min.jpg'),
          require('@/assets/images/anim/9-min.jpg'),
          require('@/assets/images/anim/10-min.jpg'),
          require('@/assets/images/anim/11-min.jpg'),
          require('@/assets/images/anim/12-min.jpg'),
          require('@/assets/images/anim/13-min.jpg'),
          require('@/assets/images/anim/14-min.jpg'),
          require('@/assets/images/anim/15-min.jpg'),
          require('@/assets/images/anim/16-min.jpg'),
          require('@/assets/images/anim/17-min.jpg'),
          require('@/assets/images/anim/18-min.jpg'),
          require('@/assets/images/anim/19-min.jpg'),
          require('@/assets/images/anim/20-min.jpg'),
          require('@/assets/images/anim/21-min.jpg'),
          require('@/assets/images/anim/22-min.jpg'),
          require('@/assets/images/anim/23-min.jpg'),
          require('@/assets/images/anim/24-min.jpg'),
          require('@/assets/images/anim/25-min.jpg'),
          require('@/assets/images/anim/26-min.jpg'),
          require('@/assets/images/anim/27-min.jpg'),
          require('@/assets/images/anim/28-min.jpg'),
          require('@/assets/images/anim/29-min.jpg'),
          require('@/assets/images/anim/30-min.jpg'),
          require('@/assets/images/anim/31-min.jpg'),
          require('@/assets/images/anim/32-min.jpg'),
          require('@/assets/images/anim/33-min.jpg'),
          require('@/assets/images/anim/34-min.jpg'),
          require('@/assets/images/anim/35-min.jpg'),
          require('@/assets/images/anim/36-min.jpg'),
          require('@/assets/images/anim/37-min.jpg'),
          require('@/assets/images/anim/38-min.jpg'),
          require('@/assets/images/anim/39-min.jpg'),
          require('@/assets/images/anim/40-min.jpg'),
          require('@/assets/images/anim/41-min.jpg'),
          require('@/assets/images/anim/42-min.jpg'),
          require('@/assets/images/anim/43-min.jpg'),
          require('@/assets/images/anim/44-min.jpg'),
          require('@/assets/images/anim/45-min.jpg'),
          require('@/assets/images/anim/46-min.jpg')
          
        ],
        window:window,
        photos: [],
        lastImage: { x: 0, y: 0 },
        animFinished: false,
        lastPosition: { x: null, y: null },
        nextId: 0, // Identifiant unique pour chaque photo
      };
    },
    methods: {
      showLogin() {
        this.displayVoile=true
        this.$emit('showLoginEv');
      },
      finishAnimation() {
        this.animFinished = true;

        setTimeout(() => {
          this.showLogin()
        }, 4000);
        var lastImageFinal;
        for(var i=0;i<this.photos.length;i++)
        {

          if(this.photos[i].opacity==1)
          {
            lastImageFinal=this.photos[i];
          }
          
        }
        setTimeout(() => {
          this.lastImage.x=lastImageFinal.x/2;
          this.lastImage.y=lastImageFinal.y/2;
        }, 1000);
      
      },
      handleTouchMove(event) {
        const touch = event.touches[0]; // Récupère la première interaction tactile
        const mouseX = touch.clientX;
        const mouseY = touch.clientY;

        this.handleMouseMove({ clientX: mouseX, clientY: mouseY }); // Réutiliser la méthode existante
      },
      handleMouseMove(event) {
        
        const mouseX = event.clientX;
        const mouseY = event.clientY;

        if (this.lastPosition.x === null || this.lastPosition.y === null) {
            this.lastPosition = { x: mouseX, y: mouseY };
            return;
        }

        const distance = Math.sqrt(
            Math.pow(mouseX - this.lastPosition.x, 2) +
            Math.pow(mouseY - this.lastPosition.y, 2)
        );

        if (distance > 150) { // Seuil de 50 pixels
           
          if(this.animFinished==false)
          {

            var directionX = mouseX > this.lastPosition.x ? 'right' : 'left';
            var directionY = mouseY > this.lastPosition.y ? 'down' : 'up';

            var distanceX = Math.abs(mouseX - this.lastPosition.x);
            var distanceY = Math.abs(mouseY - this.lastPosition.y);

            if(directionX === 'right') {
                var translationX=50+distanceX/10;
            }
            else 
            {
              var translationX=50-distanceX/10;
             
            }

            if(directionY === 'down') {

                var translationY=50+distanceY/10;
            }
            else 
            {
                var translationY=50-distanceY/10;
            }

            var currentId=this.nextId++;

            this.photos.push({
                id: currentId,
                url: this.images[this.photos.length % this.images.length],
                x: mouseX,
                y: mouseY,
                transform: "scale(0)",
                transform_topleft: "translate(-"+translationX+"%, -"+translationY+"%)",
                opacity: 0,
            });

            //console.log(`Mouse is moving ${directionX} and ${directionY}`);

            // Mettre à jour la dernière position
            this.lastPosition = { x: mouseX, y: mouseY };

           // Activer l'animation d'entrée après une frame
            setTimeout(() => {
            const photo = this.photos.find(p => p.id === currentId);
            if (photo) {
                photo.opacity = 1;
                photo.transform = "scale(1)";
                photo.x = mouseX;
                photo.y = mouseY;
                photo.transform_topleft= "translate(-50%, -50%)";
            } 
            }, 10);
          }

            if(this.animFinished==false)
            {

              // Faire disparaître les images après un certain temps avec une animation
              setTimeout(() => {
                if(this.animFinished==false)
                {
                  const photo = this.photos.find(p => p.id === currentId);
                  if (photo) {
                      photo.opacity = 0;
                      photo.transform = "scale(0)";
                  }
                }
              }, 2000); // Temps avant animation de disparition

              // Supprimer l'image après l'animation
              setTimeout(() => {
              // const photoIndex = this.photos.findIndex(p => p.id === this.nextId - 1);
              // if (photoIndex !== -1) {
              //     console.log(`Removing photo with id ${this.nextId - 1}`);
              //     this.photos.splice(photoIndex, 1); // Supprimer uniquement après toutes les animations
              // }
              // const photo = this.photos.find(p => p.id === currentId);
              // if (photo) {
              //     this.photos.shift();
              // }
              }, 3000); // Temps total avant suppression réelle

              //Limiter le nombre de photos affichées
              // if (this.photos.length > 46) {
              //   this.photos.shift();
              // }
              
            }

            
        }
      },
    },
    mounted() {
      // Ajoutez des écouteurs pour les événements tactiles
      if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
        window.addEventListener('touchmove', this.handleTouchMove);
      }
      if(this.window.innerWidth<=1200)
      {

      
        this.handleMouseMove({ clientX: this.window.innerWidth/2, clientY: this.window.innerHeight/2 });
        
        setTimeout(() => {
        this.handleMouseMove({ clientX: (this.window.innerWidth/2)*1+200*1, clientY: (this.window.innerHeight/2)*1+250*1 });
        }, 500); // Temps total avant suppression réelle
        this.handleMouseMove({ clientX: (this.window.innerWidth/2)*1+50*1, clientY: (this.window.innerHeight/2)*1+150*1 });
      }
       // Réutiliser la méthode existante
    },
    beforeDestroy() {
      // Supprimez les écouteurs pour éviter les fuites de mémoire
      if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
        window.removeEventListener('touchmove', this.handleTouchMove);
      }
    },
  };
  </script>
  
  <style scoped>


@keyframes fadevoile {
    0% {
     
      opacity:0;
    }
   
    100% {
     
      opacity:1;
     
    }
  }
  .voile 
  {
    animation:fadevoile 0.5s ease;
    position: absolute;
    opacity:1;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:rgba(0,0,0,0.5);
    z-index: 8;
  }
  .intro-container
  {

    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: transparent linear-gradient(61deg, rgba(15, 13, 15, 1) 0%, rgba(61, 57, 60, 1) 100%) 0% 0% no-repeat padding-box;
    
    z-index: 9999;
  }
  .image-follow-container {
    position: absolute;
    width: 100%;
    height: 100vh;
    transition: all 1s ease-in-out;
  }
  .image-follow-container.animate
  {
    /* width: 1000px;
    height: 1000px;
    top:50%;
    left:50%; */
    transform: scale(3);
    animation: animcontain2 4s ease-in-out;
  }
  .leaving
  {
    animation: blurappear 1s ease-in;
    opacity: 0;
  }


  @keyframes animcontain2 {
    0% {
     
      transform: none;
    }
    25% {
     
      transform: none;
    }
    75% {
    
    
      transform: scale(1);
   
    }
    100% {
     
      transform: scale(3);
     
    }
  }

  @keyframes animcontain {
    0% {
      left:0;
      width: 100%;
      height: 100vh;
      top:0;
      transform: none;
    }
    25% {
      left:0;
      width: 100%;
      height: 100vh;
      top:0;
      transform: none;
    }
    75% {
      top:50%;
      left:50%;
      transform: scale(1) translate(-50%, -50%);
      width: 1000px;
      height: 1000px;
    }
    100% {
      top:50%;
      left:50%;
      transform: scale(3) translate(-50%, -50%);
      width: 1000px;
      height: 1000px;
    }
  }

  @keyframes blurappear {
    0% {
      
      opacity: 1;
    }
    100% {
        
      opacity: 0;
    }
  }

  @keyframes name {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
        transform: scale(1);
      opacity: 1;
    }
  }
  
  .logo img 
{
    width:150px;
}
.logo
{
    position:absolute;
    top:30px;
    left:30px;
    z-index: 9;
}
.image-follow  .blur
{
  animation: blurappear 0.4s ease-in-out;
    position:absolute;
    width: 100%; /* Ajustez la taille selon vos besoins */
    height: 100%; /* Ajustez la taille selon vos besoins */
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    z-index: 2;
    border-radius: 5px;
    opacity:0;
}
  .image-follow {
    position: absolute;
    width: 250px; /* Ajustez la taille selon vos besoins */
    height: 250px; /* Ajustez la taille selon vos besoins */
    overflow: hidden;
    pointer-events: none;
    /* animation: name 0.3s ease-out; */
    
    left:auto;
    right:auto;
    
    border-radius: 5px;
    transition: transform  1s ease-in-out;
  }
  .photo-inner
  {
    position:absolute;
    left:0;
    width: 250px; /* Ajustez la taille selon vos besoins */
    height: 250px;
    top:0;
    width:100%;
   
    height:100%;
    background-size: cover;
   
    transition: transform  0.3s ease-in-out, opacity 0.2s ease-out;
   
    background-position: center;
  }
  .blur-bg
  {
    position: absolute;
    width: 100%;
    height: 100vh;
    opacity:1;
    background:transparent;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    animation: displayblur 4s ease;
    z-index: 3;
  }
  @keyframes displayblur {
    0% {
      opacity:0;
    }
    75% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  .blur-content
  {

    background-color:transparent;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    transition: all 0.3s ease-in-out;
    border-radius: 5px;
    min-width: 1000px;
    position: absolute;
   
    padding:30px 50px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 70px;
    color: white;
 
    z-index: 9999;
  }
  .button:hover
  {
    background:transparent;
    color:#fff;
  }
  .button
  {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background:#fff;
    border:1px solid #fff;
    color:#000;
    border-radius: 10px;
    font-size:18px;
    position: absolute;
    padding:10px 50px;
    z-index: 9;
    right:50px;
    bottom:50px;
  }

  @media screen and (max-width:768px) {
    #app
    {
      overflow: hidden;
    }
    .photo-inner
    {
     
      width: 150px; /* Ajustez la taille selon vos besoins */
      height: 150px;
    
    }
    .logo img {
      width:115px;
    }
    .blur-content
    {
      font-size: 25px;
      padding:20px;
      min-width: 90vw;
      text-align: center;
    }
    .logo
    {
      left:auto;
      right:30px;
      width:115px;
    }
    .button
    {
      bottom:120px;
      left:50%;
      transform: translateX(-50%);
      font-size:15px;
      right:auto !important;
    }
  }
  @media screen and (min-width:768px)  and (max-width:1024px) {
    .logo img {
      width:170px;
    }
    .blur-content
    {
      font-size: 38px;
      padding:20px;
      min-width: 90vw;
      text-align: center;
    }
    .logo
    {
      left:auto;
      right:30px;
      width:170px;
    }
    .button
    {
      left:50%;
      transform: translateX(-50%);
      font-size:18px;
    }
  }

  @media screen and (min-width:1024px)  and (max-width:1400px) {
   
    .blur-content
    {
      font-size: 55px;
      padding:20px;
      min-width: 80vw;
      text-align: center;
    }
   
    .button
    {
      
      font-size:16px !important;
    }
  }

  
  
  </style>
  