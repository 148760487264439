
<template>
  <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
    <div>
      <b-row>
        <b-col
            :md="window.innerWidth>768 && window.innerWidth <=1024 ? 12 : 6"
           class="mr-0 ml-0 pr-0 pl-0"
           
          
          >
          <b-row class="row-tof">
          <b-card title="Photo de profil"
          
           :class="{'col-md-5':window.innerWidth>768 && window.innerWidth <=1024,'col-md-12':window.innerWidth<=768 || window.innerWidth >1024}"
          >

            <div class="round-element right clickable contenudesk contenuipad" :class="{grey:editingForm==false,gray:editingForm==true}" @click="editingForm=!editingForm">
              <img src='@/assets/images/edit2.svg' class="icon" width="70" />
            </div>
            <div class="round-element black">
              <img src='@/assets/images/photo.svg' class="icon" width="70" />
            </div>
           

            <br /><br />
        
            <b-form-group
              label=""
              label-for="Photo"
            >


            
            <b-media vertical-align="top" v-if="file!==null">
              <div class="avatar-content">
                <b-avatar
                  size="150"
                  variant="light-primary"
                  :text="$user.brand_name ? $user.brand_name.charAt(0) : $user.name.charAt(0)"
                  badge
                  :src="file"
                  class="badge-minimal"
                  badge-variant="success"
                />
                <div class="bottom-avatar" v-if="editingForm || window.innerWidth<=1200" @click="editPhoto()">
                  <img src='@/assets/images/photo.svg' class="icon" width="70" />
                </div>
                <b-form-file
                
                    v-model="file"
                    :state="Boolean(file)"
                    placeholder="Choisissez ou faites glisser un fichier..."
                    drop-placeholder="Déposer ici ..."
                    @change="fileAdded($event)"
                />
              </div>
            </b-media>

            <template v-else>
              <div class="avatar-content">
                <b-avatar
                  size="150"
                  variant="light-primary"
                  :text="$user.brand_name ? $user.brand_name.charAt(0) : $user.name.charAt(0)"
                  
                  :src="file"
              
                 
                />
                <div class="bottom-avatar" v-if="editingForm || window.innerWidth<=1200">
                  <img src='@/assets/images/photo.svg' class="icon" width="70" />
                </div>
                <b-form-file
                required
                    v-model="file"
                    :state="Boolean(file)"
                    placeholder="Choisissez ou faites glisser un fichier..."
                    drop-placeholder="Déposer ici ..."
                    @change="fileAdded($event)"
                />
              </div>
              
            </template>
           
        
            </b-form-group>
              
         
  
      </b-card>
    <div class="col-md-7 pl-1 pr-0" v-if="window.innerWidth>768 && window.innerWidth<=1024">
      <b-card class="h-85" title="Informations bancaires">
        <div class="round-element right clickable contenudesk contenuipad" :class="{grey:editingForm==false,gray:editingForm==true}" @click="editingForm=!editingForm">
              <img src='@/assets/images/edit2.svg' class="icon" width="70" />
            </div>
        <div class="round-element black">
          <img src='@/assets/images/card.svg' class="icon" width="70" />
        </div>
        <b-row>
          <AccountOnboarding />
          <!-- <div class="container">
      <b-button 
      
      >
      Demander le payout
      </b-button>
      </div> -->
        </b-row>

        

      </b-card>
    </div>
    </b-row>
      <b-card v-if="window.innerWidth<=1024" :class="{'col-md-12':window.innerWidth>768 && window.innerWidth <=1024}" title="Informations profil" >
        <div class="round-element right clickable contenudesk contenuipad" :class="{grey:editingForm==false,gray:editingForm==true}" @click="editingForm=!editingForm">
              <img src='@/assets/images/edit2.svg' class="icon" width="70" />
            </div>
        <div class="round-element blue">
          <img src='@/assets/images/profile.svg' class="icon" width="70" />
        </div>
        <br />
        <b-row>
          
          <b-col
            md="6"
           
            class="mb-1"
          >
            <b-form-group
              label="Nom"
              label-for="basicInput"
            >
              <b-form-input
               required
               @input="editingForm=true"
                id="basicInput"
                v-model="form.name"
               
                placeholder="Nom"
              />
            </b-form-group>

          </b-col>
          <b-col
            md="6"
          
            class="mb-1"
          >
          <b-form-group
              label="Prénom"
              label-for="basicInput"
            >
              <b-form-input
               required
               @input="editingForm=true"
                id="basicInput"
                v-model="form.surname"
               
                placeholder="Prénom"
              />
            </b-form-group>
              
          </b-col>
     

        </b-row>
        <b-row>
          
          <b-col
            md="12"
           
            class="mb-1"
          >
            <b-form-group
              label="Email"
              label-for="basicInput"
            >
              <b-form-input
               required
               @input="editingForm=true"
                id="basicInput"
                v-model="form.email"
               
                placeholder="Email"
              />
            </b-form-group>

          </b-col>
          
     

        </b-row>

        <b-row>
          
          <b-col
            md="6"
           
            class="mb-1"
          >
            <b-form-group
              label="Phone"
              label-for="basicInput"
            >
              <b-form-input
               required
                id="basicInput"
                v-model="form.phone"
               @input="editingForm=true"
                placeholder="Phone"
              />
            </b-form-group>

          </b-col>
          
            <b-col
              md="6"
             :class="{hidden:!seeMore && window.innerWidth<768}"
              class="mb-1"
            >
            <b-form-group
                label="Adresse"
                label-for="basicInput"
              >
                <b-form-input
                required
                  id="basicInput"
                  v-model="address_form.address"
                @input="editingForm=true"
                  placeholder="Adresse"
                />
              </b-form-group>
                
            </b-col>
          
     

        </b-row>
        <a v-if="!seeMore && window.innerWidth<768" class="seemore" @click="seeMore=true">Voir plus</a>
        <div :class="{hidden:!seeMore  && window.innerWidth<768}">
          <b-row>
            
            <b-col
              md="6"
            
              class="mb-1"
            >
              <b-form-group
                label="Code postal"
                label-for="basicInput"
              >
                <b-form-input
                required
                @input="editingForm=true"
                  id="basicInput"
                  v-model="address_form.postal_code"
                
                  placeholder="Code postal"
                />
              </b-form-group>

            </b-col>
            <b-col
              md="6"
            
              class="mb-1"
            >
            <b-form-group
                label="Ville"
                label-for="basicInput"
              >
                <b-form-input
                required
                @input="editingForm=true"
                  id="basicInput"
                  v-model="address_form.city"
                
                  placeholder="Ville"
                />
              </b-form-group>
                
            </b-col>
      

          </b-row>

          <b-row>
            
            <b-col
              md="6"
            
              class="mb-1"
            >
              <b-form-group
                label="Pays"
                label-for="basicInput"
              >
                <b-form-input
                required
                @input="editingForm=true"
                  id="basicInput"
                  v-model="address_form.country"
                
                  placeholder="Pays"
                />
              </b-form-group>

            </b-col>
            <b-col
              md="6"
            
              class="mb-1"
            >
            <b-form-group
                label="Additional information"
                label-for="basicInput"
              >
                <b-form-input
                @input="editingForm=true"
                required
                  id="basicInput"
                  v-model="address_form.additional_information"
                
                  placeholder="Additional information"
                />
              </b-form-group>
                
            </b-col>
      

          </b-row>

          <b-row>
            
            <b-col
              md="12"
            
              class="mb-1"
            >
              <b-form-group
                label="Biographie"
                label-for="basicInput"
                :class="{'form-group-plaintext':suggestBio}"
              >
                <b-form-textarea
                required
                  @input="editingForm=true"
                  id="basicInput"
                  v-model="form.bio"
                  :plaintext="suggestBio"
                  placeholder="Biographie"
                />
                <img class="pending-icon" src='@/assets/images/sablier2.svg' v-if="suggestBio" width="20" />
                <div  v-if="suggestBio" class="current-suggest-popup">
                  Votre modification est en cours de validation. Vous recevrez un email de confirmation sous peu.
                </div> 
              </b-form-group>

            </b-col>
            
      

          </b-row>
        </div>
      <div class="buttons" v-if="editingForm">
        <b-button 
        variant="outline-primary"
          class="bottom-btn second"
       @click="editingForm=false"
      >
      Annuler
      </b-button>
      <b-button 
       class="bottom-btn"
       @click="editProfile"
      >
      Valider
      </b-button>
  </div>
      </b-card>
      

      <b-card title="Mes réseaux sociaux">
        <div class="round-element right clickable contenudesk contenuipad" :class="{grey:editingFormRs==false,gray:editingFormRs==true}" @click="editingFormRs=!editingFormRs">
              <img src='@/assets/images/edit2.svg' class="icon" width="70" />
            </div>
        <div class="round-element black">
          <img src='@/assets/images/share.svg' class="icon" width="70" />
        </div>
        <br /><br />
        <b-row>
          
          <b-col
            md="6"
           
            class="mb-1"
          >
            <b-form-group
             class="rs-form"
              :class="{'form-group-plaintext':suggestInstagram}"
            >
            <img src='@/assets/images/insta.svg' class="icon-label" width="70" />
              <b-form-input
               required
               @input="editingFormRs=true"
                id="basicInput"
                v-model="form.instagram"
               :plaintext="suggestInstagram"
                placeholder="Instagram"
              />
              <img class="pending-icon" src='@/assets/images/sablier2.svg' v-if="suggestInstagram" width="20" />
              <div  v-if="suggestInstagram" class="current-suggest-popup">
                Votre modification est en cours de validation. Vous recevrez un email de confirmation sous peu.
              </div> 
            </b-form-group>

          </b-col>
          <b-col
            md="6"
          
            class="mb-1"
          >
          <b-form-group
             class="rs-form"
              :class="{'form-group-plaintext':suggestLinkedin}"
            >
            <img src='@/assets/images/li.svg' class="icon-label" width="70" />
              <b-form-input
               required
                id="basicInput"
                v-model="form.linkedin"
               @input="editingFormRs=true"
               :plaintext="suggestLinkedin"
                placeholder="LinkedIn"
              />
              <img class="pending-icon" src='@/assets/images/sablier2.svg' v-if="suggestLinkedin" width="20" />
              <div  v-if="suggestLinkedin" class="current-suggest-popup">
                Votre modification est en cours de validation. Vous recevrez un email de confirmation sous peu.
              </div> 
            </b-form-group>
              
          </b-col>
     

        </b-row>

        <b-row>
          
          <b-col
            md="6"
            

          >
            <b-form-group
             class="rs-form"
              :class="{'form-group-plaintext':suggestX}"
            >
            <img src='@/assets/images/x.svg' class="icon-label" width="70" />
              <b-form-input
               required
                id="basicInput"
                v-model="form.x"
                :plaintext="suggestX"
               @input="editingFormRs=true"
                placeholder="X"
              />
              <img class="pending-icon" src='@/assets/images/sablier2.svg' v-if="suggestX" width="20" />
              <div  v-if="suggestX" class="current-suggest-popup">
                Votre modification est en cours de validation. Vous recevrez un email de confirmation sous peu.
              </div> 
            </b-form-group>

          </b-col>
          <b-col
            md="6"
          
            class="mb-1"
          >
          <b-form-group
             class="rs-form"
              :class="{'form-group-plaintext':suggestFacebook}"
            >
            <img src='@/assets/images/fb.svg' class="icon-label" width="70" />
              <b-form-input
               required
               @input="editingFormRs=true"
                id="basicInput"
                :plaintext="suggestFacebook"
                v-model="form.facebook"
               
                placeholder="Facebook"
              />
              <img class="pending-icon" src='@/assets/images/sablier2.svg' v-if="suggestFacebook" width="20" />
              <div  v-if="suggestFacebook" class="current-suggest-popup">
                Votre modification est en cours de validation. Vous recevrez un email de confirmation sous peu.
              </div> 
            </b-form-group>
              
          </b-col>

         
     

        </b-row>
        <div class="buttons" v-if="editingFormRs">
              <b-button 
              variant="outline-primary"
                class="bottom-btn second"
            @click="editingFormRs=false"
            >
            Annuler
            </b-button>
            <b-button 
            class="bottom-btn"
            @click="editProfile"
            >
            Valider
            </b-button>
        </div>
  
      </b-card>

      <b-card v-if="window.innerWidth<768 || window.innerWidth>1024" title="Informations bancaires">
        <div class="round-element right clickable contenudesk contenuipad" :class="{grey:editingForm==false,gray:editingForm==true}" @click="editingForm=!editingForm">
              <img src='@/assets/images/edit2.svg' class="icon" width="70" />
            </div>
        <div class="round-element black">
          <img src='@/assets/images/card.svg' class="icon" width="70" />
        </div>
        <b-row>
          <AccountOnboarding />
          <!-- <div class="container">
      <b-button 
      
      >
      Demander le payout
      </b-button>
      </div> -->
        </b-row>

        

      </b-card>


    </b-col>

        <b-col
            md="6"
           
         
          >
      <b-card v-if="window.innerWidth>1024" title="Informations profil" class="h-90">
        <div class="round-element right clickable contenudesk contenuipad" :class="{grey:editingForm==false,gray:editingForm==true}" @click="editingForm=!editingForm">
              <img src='@/assets/images/edit2.svg' class="icon" width="70" />
            </div>
        <div class="round-element blue">
          <img src='@/assets/images/profile.svg' class="icon" width="70" />
        </div>
        <br />
        <b-row>
          
          <b-col
            md="6"
           
            class="mb-1"
          >
            <b-form-group
              label="Nom"
              label-for="basicInput"
            >
              <b-form-input
               required
               @input="editingForm=true"
                id="basicInput"
                v-model="form.name"
               
                placeholder="Nom"
              />
            </b-form-group>

          </b-col>
          <b-col
            md="6"
          
            class="mb-1"
          >
          <b-form-group
              label="Prénom"
              label-for="basicInput"
            >
              <b-form-input
               required
               @input="editingForm=true"
                id="basicInput"
                v-model="form.surname"
               
                placeholder="Prénom"
              />
            </b-form-group>
              
          </b-col>
     

        </b-row>
        <b-row>
          
          <b-col
            md="12"
           
            class="mb-1"
          >
            <b-form-group
              label="Email"
              label-for="basicInput"
            >
              <b-form-input
               required
               @input="editingForm=true"
                id="basicInput"
                v-model="form.email"
               
                placeholder="Email"
              />
            </b-form-group>

          </b-col>
          
     

        </b-row>

        <b-row>
          
          <b-col
            md="6"
           
            class="mb-1"
          >
            <b-form-group
              label="Phone"
              label-for="basicInput"
            >
              <b-form-input
               required
                id="basicInput"
                v-model="form.phone"
               @input="editingForm=true"
                placeholder="Phone"
              />
            </b-form-group>

          </b-col>
          <b-col
            md="6"
          
            class="mb-1"
          >
          <b-form-group
              label="Adresse"
              label-for="basicInput"
            >
              <b-form-input
               required
                id="basicInput"
                v-model="address_form.address"
               @input="editingForm=true"
                placeholder="Adresse"
              />
            </b-form-group>
              
          </b-col>
     

        </b-row>

        <b-row>
          
          <b-col
            md="6"
           
            class="mb-1"
          >
            <b-form-group
              label="Code postal"
              label-for="basicInput"
            >
              <b-form-input
               required
               @input="editingForm=true"
                id="basicInput"
                v-model="address_form.postal_code"
               
                placeholder="Code postal"
              />
            </b-form-group>

          </b-col>
          <b-col
            md="6"
          
            class="mb-1"
          >
          <b-form-group
              label="Ville"
              label-for="basicInput"
            >
              <b-form-input
               required
               @input="editingForm=true"
                id="basicInput"
                v-model="address_form.city"
               
                placeholder="Ville"
              />
            </b-form-group>
              
          </b-col>
     

        </b-row>

        <b-row>
          
          <b-col
            md="6"
           
            class="mb-1"
          >
            <b-form-group
              label="Pays"
              label-for="basicInput"
            >
              <b-form-input
               required
               @input="editingForm=true"
                id="basicInput"
                v-model="address_form.country"
               
                placeholder="Pays"
              />
            </b-form-group>

          </b-col>
          <b-col
            md="6"
          
            class="mb-1"
          >
          <b-form-group
              label="Additional information"
              label-for="basicInput"
            >
              <b-form-input
              @input="editingForm=true"
               required
                id="basicInput"
                v-model="address_form.additional_information"
               
                placeholder="Additional information"
              />
            </b-form-group>
              
          </b-col>
     

        </b-row>

        <b-row>
          
          <b-col
            md="12"
           
            class="mb-1"
          >
            <b-form-group
              label="Biographie"
              label-for="basicInput"
              :class="{'form-group-plaintext':suggestBio}"
            >
              <b-form-textarea
               required
                @input="editingForm=true"
                id="basicInput"
                v-model="form.bio"
                :plaintext="suggestBio"
                placeholder="Biographie"
              />
              <img class="pending-icon" src='@/assets/images/sablier2.svg' v-if="suggestBio" width="20" />
              <div  v-if="suggestBio" class="current-suggest-popup">
                Votre modification est en cours de validation. Vous recevrez un email de confirmation sous peu.
              </div> 
            </b-form-group>

          </b-col>
          
     

        </b-row>
      <div class="buttons" v-if="editingForm">
        <b-button 
        variant="outline-primary"
          class="bottom-btn second"
       @click="editingForm=false"
      >
      Annuler
      </b-button>
      <b-button 
       class="bottom-btn"
       @click="editProfile"
      >
      Valider
      </b-button>
  </div>
      </b-card>

      
  </b-col>
    </b-row>
    </div>
  </b-overlay>
  </template>
  
  <script>
  import { BButton,BAvatar,BFormInput,BOverlay, BRow, BCol,BCardText,BFormGroup,BFormFile, BMedia,BCard, BFormCheckbox, BFormTextarea} from 'bootstrap-vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import vSelect from 'vue-select'
  import {APIRequest,Auth} from '@simb2s/senseye-sdk'
  import AccountOnboarding from '../../components/AccountOnboarding.vue'

  export default {
    name: 'Profile',
    components: {
      BButton,
      BMedia,
      BAvatar,
      BFormInput,
      BCardText,
      BFormGroup,
      BRow,
      BCol,
      AccountOnboarding,
      BFormFile,
      BOverlay,
      BCard,
      BFormCheckbox,
      BFormTextarea,
      vSelect
    },
    data() {
        return {
          window:window,
          seeMore:false,
          editingForm:false,
          editingFormRs:false,
            edition:false,
            loading:true,
            profile_suggests:[],
            suggestX:false,
            suggestInstagram:false,
            suggestLinkedin:false,
            suggestFacebook:false,
            suggestBio:false,
            suggestName:false,
            suggestSurname:false,
            address_default:null,
            currentBase64:null,
            user:null,
            file: null,
            address_form: {
              address: "",
              city: "",
              country: "",
              postal_code: "",
              additional_information: "",
            },
            form:{
              name:"",
              surname:"",
              email:"",
              phone:"",
              bio:"",
              brand_name:"",
              instagram:"",
              linkedin:"",
              facebook:"",
              x:"",
              brand_logo:null,
              stl_files:false
            },
        }
    },
   
    methods:{
      editPhoto() {
        this.file=null
      },
      fileAdded(event)
      {
       this.editingForm=true
        var reader = new FileReader()
        reader.readAsDataURL(event.target.files[0])
        reader.onload = () => {
            
            let b64=reader.result.split(';')
            let type=b64[0]
            if(type.includes('image'))
            {
                let base64=b64[1]
                //base64.replace('base64,','')
                this.currentBase64=reader.result
                this.file=this.currentBase64
                this.form.brand_logo=this.currentBase64
            }
            else
            {
                this.file=null
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Ceci n'est pas une image !",
                    icon: 'AlertCircleIcon',
                    variant: 'warning',
                  },
                })
            }
            
        };
      },
      async initDatas() {
        let user=await Auth.get_user_with_id(localStorage.id_user_senseye_auth)
        this.user=user
        this.form.brand_logo=user.brand_logo
        this.form.name=user.name
        this.form.surname=user.surname
        this.form.email=user.email
        this.form.phone=user.phone
        this.form.bio=user.biography
        this.form.linkedin=user.linkedin
        this.form.facebook=user.facebook
        this.form.instagram=user.instagram
        this.form.x=user.x
        console.log('user logo')
        console.log(user.brand_logo)
        //this.form.brand_name=user.brand_name
        //this.form.stl_files=user.stl_files
        if(user.brand_logo)
      {
        this.file=user.brand_logo
      }
        

        // get address default of user
        let addresses=await APIRequest.list('addresses',localStorage.id_user_senseye_auth)
      
        if(addresses[0].length>0)
        {
          this.address_default=addresses[0][0]
          this.address_form.address=addresses[0][0].address1
          this.address_form.city=addresses[0][0].city
          this.address_form.country=addresses[0][0].country
          this.address_form.postal_code=addresses[0][0].postcode
          this.address_form.additional_information=addresses[0][0].address2
        }

        let profile_suggests=await APIRequest.list_with_request('profile-suggests','?user='+localStorage.id_user_senseye_auth)
        if(profile_suggests[0])
        {

          this.profile_suggests=profile_suggests[0]
        
        for(let i=0;i<this.profile_suggests.length;i++)
        {
          if(this.profile_suggests[i].name)
          {
            this.suggestName=true
          }
          if(this.profile_suggests[i].surname)
          {
            this.suggestSurname=true
          }
          if(this.profile_suggests[i].bio)
          {
            this.suggestBio=true
          }
          if(this.profile_suggests[i].x)
          {
            this.suggestX=true
          }
          if(this.profile_suggests[i].instagram)
          {
            this.suggestInstagram=true
          }
          if(this.profile_suggests[i].linkedin)
          {
            this.suggestLinkedin=true
          }
          if(this.profile_suggests[i].facebook)
          {
            this.suggestFacebook=true
          }

        }
        }
       

      },
      async editProfile()
      {
        this.loading=true

        let datas = {}

          if(this.form.name!=this.user.name)
          {
            datas.name = this.form.name
          }
          if(this.form.surname!=this.user.surname)
          {
            datas.surname = this.form.surname
          }
          if(this.form.bio!=this.user.biography)
          {
            datas.bio = this.form.bio
          }
          if(this.form.x!=this.user.x)
          {
            datas.x = this.form.x
          }
          if(this.form.linkedin!=this.user.linkedin)
          {
            datas.linkedin = this.form.linkedin
          }
          if(this.form.instagram!=this.user.instagram)
          {
            datas.instagram = this.form.instagram
          }

          if(datas)
          {
            datas.user={id:localStorage.id_user_senseye_auth}
            await APIRequest.create('profile-suggests',datas)
          }

          datas={}
          datas.brand_logo = this.form.brand_logo
         
          datas.phone = this.form.phone
          datas.email = this.form.email
         
          //datas.stl_files = this.form.stl_files
          
          try{
               
              await Auth.update_account(localStorage.id_user_senseye_auth, datas)
              let params={
                name:'Adresse par défaut',
                first_name:this.form.name,
                last_name:this.form.surname,
                address1:this.address_form.address,
                city:this.address_form.city,
                phone:this.form.phone,
                country:this.address_form.country,
                postcode:this.address_form.postal_code,
                address2:this.address_form.additional_information,
                default_address:true,
                user:{id:localStorage.id_user_senseye_auth}
              }
              //create address
              if(!this.address_default)
              {
                this.address_default=await APIRequest.create('addresses',params)
              }
              else
              {
                //update address
                this.address_default=await APIRequest.update('addresses',this.address_default.id,params)
              }

              this.loading=false
              this.$toast({
                  component: ToastificationContent,
                  props: {
                      title: 'Votre profil a bien été modifié !',
                      icon: 'CheckIcon',
                      variant: 'success',
                  },
              })

              
            
              
          } catch (error) {
            
            this.loading=false
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Une erreur est survenue.',
                    icon: 'AlertCircleIcon',
                    variant: 'warning',
                },
            })
            
          }


        
      },
   
    },
  
    
      
    async mounted(){

     await this.initDatas()
      this.loading=false
    }
  
  }
  </script>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .h-90
  {
    height:98%;
  }
  .buttons
  {
    display:flex;
    justify-content: space-between;
  }
  .bottom-btn
  {
    width:49%;
  }
  .avatar-content
  {
    width:150px;
    height: 150px;
    border-radius: 50%;
    position:relative;
    display: inline-block;
    margin-left: auto;
    left:50%;
    transform: translateX(-50%);
    margin-right: auto;
    overflow: hidden;
  }
  .bottom-avatar img 
  {
    width:35px;
  }
  .custom-file
  {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    opacity:0;
    height:100%;
  }
  .bottom-avatar
  {
    background-color:rgba(0,0,0,0.5);
    position: absolute;
    bottom: 0;
    width:100%;
    text-align: center;
    padding-top:10px;
    padding-bottom:10px;
  }
  @media screen and (max-width: 768px) {
    
    .avatar-content
    {
      width:125px;
      height:125px;
    }
    .seemore 
    {
      color:#999999 !important;
      font-size:12px;
      display:block;
      width:fit-content;
      margin-left:auto;
      margin-right:auto;
      border-bottom:1px solid #999999;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .h-85
    {
      height:95% !important;
    }
    .avatar-content
    {
      width:125px;
      height:125px;
    }
    .seemore 
    {
      color:#999999 !important;
      font-size:12px;
      display:block;
      width:fit-content;
      margin-left:auto;
      margin-right:auto;
      border-bottom:1px solid #999999;
    }
  }
  @media screen and (min-width: 1200px) {

.row-tof
{
  padding-left:1rem;
  padding-right:1rem;
}

}
  @media screen and (min-width: 1024px) and (max-width: 1200px) {

    .row-tof
    {
      padding-left:1rem;
      padding-right:1rem;
    }
    .contenuipad
    {
      display: none;
    }
  }
  </style>