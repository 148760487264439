
<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
  <div>
    <br />
   
       <validation-observer ref="simpleRules">
        <b-form
          @submit.prevent
        >
        <b-row>
         

          <b-col
            md="12"
         
            class="mb-1"
          >
            <b-form-group
              label="Nom du code promotionnel*"
              label-for="basicInput"
            >

             <validation-provider
              #default="{ errors }"
              name="Code"
              rules="required"
            >
              <b-form-input
              required
                class="input-upper"
                id="basicInput"
                v-model="form.code"
                :plaintext="readOnly"
                placeholder="Ex : WINTER01"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
          
            class="mb-1"
          >
          <b-form-group
              label="Produit concerné*"
              label-for="Type de verre"
            >
            
              <v-select
              :components="{Deselect}"
                v-model="form.products"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                placeholder="Choisir..."
                
                :options="productsChoices"
              />
              
            </b-form-group>
          </b-col>
         
          

          <b-col
            md="6"
          
            class="mb-1"
          >
          <b-form-group
              label="Valeur de la réduction (%)*"
              label-for="Prix"
            >
            
         
              <v-select
               :components="{Deselect}"
                v-model="form.amount"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
               placeholder="Choisir..."
                
                :options="percentageChoices"
              />
              
            </b-form-group>
            <span v-if="form.amount && form.amount!=0" class="simu-legend">Prix public estimé avec réduction de {{form.amount.value}}% = {{ promoAmount }}€</span>
          </b-col>

          <b-col
            md="6"
          
            class="mb-1"
          >
          <b-form-group
              label="Date de début"
              label-for="Date de fin"
            >
              <b-form-datepicker
             
                id="example-datepicker"
                v-model="form.begin_date"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="fr"
                class="mb-1"
              />
            </b-form-group>
          </b-col>

            <b-col
            md="6"
          
            class="mb-1"
          >
          <b-form-group
              label="Date de fin"
              label-for="Date de fin"
            >
              <b-form-datepicker
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="fr"
                id="example-datepicker2"
                v-model="form.end_date"
                class="mb-1"
              />
            </b-form-group>
          </b-col>

         

       
          

           <b-col
            md="6"
           
            class="mb-1"
          >
          <b-form-group
              label="Plafond total d’utilisations*"
              label-for="Prix"
            >
              <b-form-input
                id="Prix"
                v-model="form.usage_limit"
                :plaintext="readOnly"
                type="number"
                step="1"
                placeholder="Vide = illimité"
              />
            </b-form-group>
          </b-col>

          <b-col
            md="6"
          
            class="mb-1"
          >
          <b-form-group
              label="Limite d’utilisation par client*"
              label-for="Prix"
            >
              <b-form-input
                id="Prix"
                v-model="form.user_limit"
                :plaintext="readOnly"
                type="number"
                step="1"
                placeholder="Exemple : 4"
              />
            </b-form-group>
          </b-col>

          <b-col
            md="6"
          
            class="mb-1"
          >
          <b-form-group
              label="Activé"
              label-for="Actif"
            >
              <b-form-checkbox
                v-model="form.active"
                
                class="custom-control-primary"
              >
                
              </b-form-checkbox>
            </b-form-group>
          </b-col>


        </b-row>
      </b-form>
      </validation-observer>


      <b-button v-if="edition==false"
      class="button-big-black"
     @click="createPromotion"
    >
    Générer
    </b-button>

    <b-button v-else
     @click="editPromotion"
    >
    Enregistrer
    </b-button>

  </div>
</b-overlay>
</template>

<script>
import { BButton,BFormInput, BForm, BRow, BOverlay,BFormDatepicker, BCol, BFormGroup,BFormFile, BCard, BFormCheckbox, BFormTextarea} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import {APIRequest,Utils} from '@simb2s/senseye-sdk'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { locale } from 'core-js'


export default {
  name: 'PromotionForm',
  components: {
    BButton,
    BFormInput,
    BFormGroup,
    BOverlay,
    BRow,
    BCol,
    BFormFile,
    BCard,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormDatepicker
  },
  data() {
      return {

          Deselect: {
            render: createElement => createElement('span', 'x'),
          },
          currentBase64:null,
          file: null,
          promoAmount:0,
          loading:true,
          products:[],
          productsChoices:[],
          productsCategoriesChoices: [
            {
              title: 'Collection',
              value: 'collection',
            },
            {
              title: 'Collaboration',
              value: 'collaboration',
            }
          ],
          typeChoices: [
            {
              label: 'Remise fixe (en €)',
              value: 'fixed',
            },
            {
              label: 'Pourcentage',
              value: 'percentage',
            }
          ],
          edition:false,
          percentageChoices:[
            {
              title:'100%',value:100
            },
            {
              title:'90%',value:90
            },
            {
              title:'80%',value:80
            },
            {
              title:'70%',value:70
            },
            {
              title:'60%',value:60
            },
            {
              title:'50%',value:50
            },
            {
              title:'40%',value:40
            },
            {
              title:'30%',value:30
            },
            {
              title:'20%',value:20
            },
            {
              title:'10%',value:10
            }
            
            ],
          form:{
            name:"",
            code:'',
            user:{id:localStorage.id_user_senseye_auth},
            amount:0,
            promotion_type:'creator',
            active:true,
            type:'percentage',
            use_count:0,
            free_shipping:false,
            begin_date:null,
            end_date:null,
            minimum_spend:null,
            maximum_spend:null,
            individual_use:true,
            exclude_sale_items:false,
            products:null,
            product_categories:null,
            allowed_emails:null,
            usage_limit:null,
            user_limit:null
          },
      }
  },
  watch:{
    "form.products":function() {
      console.log('product change')
      this.calculatePromoAmount()
    },
    "form.amount":function() {
      console.log('amount change')
      this.calculatePromoAmount()
    }
  },
  props: ['promotion', 'readOnly'],
  methods:{

    calculatePromoAmount()
    {
      let promoAmount=0
   
      if(this.form.products && this.form.amount)
      {
       
        for(let i=0;i<this.products.length;i++)
        {
          console.log(this.products[i])
          if(this.products[i].id==this.form.products.value)
          {
         
            let amount_gross= this.products[i].price - this.products[i].production_price
            let amount_comission=(amount_gross/100)*this.products[i].comission
            promoAmount=(amount_comission/100)*this.form.amount.value
          }
        }
      }
      this.promoAmount=promoAmount.toFixed(2)
    },
    async getProducts() {
      let products = await APIRequest.list_with_request('glasses', '?creator_owner='+localStorage.id_user_senseye_auth)

      
      this.products=products[0];
      this.productsChoices=Utils.formatSelect(this.products,'name','id')
     
    },
    async createPromotion()
    {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
        this.loading=true
        try {
          let datas=this.form
          this.form.products=[{id:this.form.products.value}]
          this.form.amount=this.form.amount.value

          if(this.form.begin_date)
          {
            let date=Date.parse(this.form.begin_date)
            date=new Date(date)

            let day = date.getDate();
            let month =  date.getMonth();
            let year = date.getFullYear();
        
            datas.begin_date=day + "/" + month*1+1*1 + "/" + year;
          }

          if(this.form.end_date)
          {
            let date=Date.parse(this.form.end_date)
            date=new Date(date)

            let day = date.getDate();
            let month =  date.getMonth();
            let year = date.getFullYear();
        
            datas.end_date=day + "/" + month*1+1*1 + "/" + year;
          }
          await APIRequest.create('promotions',datas)

          this.loading=false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'La promotion a bien été créée !',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          //this.$router.push({name:'promotions'})
          this.refreshList()
        } catch (error) {
          this.loading=false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Une erreur est survenue.',
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          
        }
        }
      })
      
    },
   async editPromotion()
    {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
        this.loading=true
        try {
          let datas=this.form
          this.form.products=[{id:this.form.products.value}]
          this.form.amount=this.form.amount.value
         
          if(this.form.begin_date)
          {
            let date=Date.parse(this.form.begin_date)
            date=new Date(date)

            let day = date.getDate();
            let month =  date.getMonth();
            let year = date.getFullYear();
        
            datas.begin_date=day + "/" + month*1+1*1 + "/" + year;
          }

          if(this.form.end_date)
          {
            let date=Date.parse(this.form.end_date)
            date=new Date(date)

            let day = date.getDate();
            let month =  date.getMonth();
            let year = date.getFullYear();
        
            datas.end_date=day + "/" + month*1+1*1 + "/" + year;
          }

          
          await APIRequest.update('promotions',this.promotion.id,datas)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'La promotion a bien été modifiée !',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.refreshList()
        } catch (error) {
          this.loading=false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Une erreur est survenue.',
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          
        }
        }
      })
      
    },
    refreshList(){
      this.$emit('refreshListEv')
    }
     
      
  },
  async mounted(){
    await this.getProducts()
    if(this.promotion!=null)
    {
      this.form=this.promotion
      if(this.promotion.products.length>0)
      {   
     

        for(let i=0;i<this.productsChoices.length;i++)
        {
          if(this.promotion.products[0].id==this.productsChoices[i].value)
          {
            this.form.products=this.productsChoices[i]
          }
        }
        
      }

      if(this.promotion.amount)
      {
        for(let i=0;i<this.percentageChoices.length;i++)
        {
          if(this.promotion.amount==this.percentageChoices[i].value)
          {
            this.form.amount=this.percentageChoices[i]
          }
        }
      }
     
      this.file=this.promotion.photo
      this.edition=true
      this.calculatePromoAmount()
   
    }
    this.loading=false
  }

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.input-upper{
  text-transform: uppercase;
}
.button-big-black
{
  min-width: 200px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
</style>