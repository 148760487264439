export default [
  {
    title: 'Accueil',
    route: 'home',
    icon: 'home',
  },
  {
    title: 'Mes produits',
    route: 'glasses',
    icon: 'glass',
  },

  {
    title: 'Codes promo',
    route: 'promotions',
    icon: 'percent',
  },
  
  {
    title: 'Paiements',
    route: 'payouts',
    icon: 'card',
  },
 
 
  
  {
    title: 'Mon profil',
    route: 'profile',
    icon: 'profile',
  },
 
  // {
  //   title: 'Statistiques',
  //   route: 'analytics',
  //   icon: 'ActivityIcon',
  // },


]
