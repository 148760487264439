<template>

  <div class="user-profile">
  
    <br /><br />
          
          <center>
            <b-avatar
              :size="window.innerWidth <= 1200 ? 100 : 150"
              variant="light-primary"
              :text="$user.brand_name ? $user.brand_name.charAt(0) : $user.name.charAt(0)"
              badge
              
              class="badge-minimal"
              badge-variant="success"
            />
          </center>
          <br />
          <div class="">
            <p class="user-name mb-0">
              <span v-if="$user.brand_name">{{$user.brand_name}}</span>
              <span v-else>{{$user.name}} {{$user.surname}}</span>
             
            </p>
            <!-- <span class="user-status">Admin</span> -->
          </div>
          <br /><br />
          <div class="logout-link">
          <a link-class="d-flex align-items-center" @click="logout()">
            <img src='@/assets/images/disconnect.svg' width="16"  :style="'margin-right:10px;'" />
            
                <span>Déconnexion</span>
              </a>

            <div class="rs-links">
              <a href="#" target="_blank" class="me-1">
                <img src='@/assets/images/insta.svg' width="16" />
              </a>
             
              <a href="#" target="_blank">
                <img src='@/assets/images/li.svg' width="16" />
              </a>
              <a href="#" target="_blank" class="me-1">
                <img src='@/assets/images/fb.svg' width="16" />
              </a>

              <a class="website" href="https://visages.com">visages.com</a>
            </div>
          </div>
          

        <b-dropdown-divider />

       

      </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import {Auth} from "@simb2s/senseye-sdk"
export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      window:window
    }
  },
  methods: {
    async logout()
    {
      await Auth.logout()
      document.location.reload(); 
    },
  }
}
</script>

<style scoped>
.user-name
{
  font-weight: 500;
  font-size:20px;
  text-align: center;
}
.user-profile
{
  display:block;
  width:100%;
  
}
.logout-link
{
  display: block;
  position: fixed;
  bottom:40px;
  z-index:3;
  padding-left:7px;
}
.rs-links a {
  margin-right: 10px;
}
.rs-links img {
  height:17px  !important;
  width: auto !important;
}
.rs-links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.rs-links a.website {
  padding:5px 10px;
  margin-left:5px;
  border:1px solid #000;
  font-size: 13px;
  border-radius: 10px;
}


@media screen and (max-width:768px) {
  
  .user-profile
  {

    line-height: 0.8;
  }
}
</style>