<!-- src/components/GlassItem.vue -->
<template>
    <div class="col-md-4 glass-item" v-if="!loading && glass.active">
        <b-overlay
            :show="loadedImages < photoDatas.length"
            spinner-variant="primary"
            spinner-type="grow"
            :opacity="1"
            spinner-small
            rounded="sm"
            style="height:90%"
            >
            <div class="grey-image" @click="$router.push({name:'editGlass', params: { id: glass.id }})">
                <div class="img">
                    <img v-for="(layer, index_layer) in photoDatas" @load="imageLoaded" :key="index_layer" :src="calculatePhoto(layer)" :class="{'render-layer2':index_layer>0}"  :style="'opacity:'+getOpacityElement(index_layer)+';'" />
                </div>
            </div>
        </b-overlay>
        <div class="bottom-product">
            <div class="title-glass">{{glass.name}}</div>
            <div class="right-bottom">
                <div class="price-glass">{{glass.price}}€</div>
                <div class="button-glass" @click="$router.push({name:'editGlass', params: { id: glass.id }})">Voir</div>
            </div>
        </div>
    </div>
</template>

<script>
import {APIRequest} from '@simb2s/senseye-sdk'
import {BModal, BTable, BTableSimple, BTableLite, BTd, BTbody, BTr, BTh, BThead, BPagination, BRow, BButton, VBModal,BOverlay, BCard, BCardText, BAvatar, BBadge, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'

export default {
    props: ['glass'],
    components: {
        BOverlay,

    },
    data() {
        return {
            photoDatas:[1,2,3],
            loading:true,
            loadedImages: 0,
        }
    },
    methods:{
        imageLoaded() {
            this.loadedImages++
        },
        calculatePhoto(layer_info) {
            let hue = layer_info.presets.hue_app ? layer_info.presets.hue_app : 100
            let saturation = layer_info.presets.saturation ? layer_info.presets.saturation : 100
            let brightness = layer_info.presets.brightness ? layer_info.presets.brightness : 100


            let url
            if(brightness==100 && hue==100 && saturation==100)
            {
            url = 'https://api.sens-eye.fr/render_parts/'+layer_info.render
            }
            else {
            url = 'https://api.sens-eye.fr/api/photo-single-test?photo='+layer_info.render+'&hue='+hue+'&saturation='+saturation+'&brightness='+brightness
            }

            //this.$emit('calculatePhotoEv',0)

            return url
            

        },
        
        getOpacityElement(layer) {
          if(this.photoDatas[layer].presets.opacity != null) {
                return this.photoDatas[layer].presets.opacity/100
            }
            else {
                return 1
            }
        },
  async calculatePhotos(){
    let request = '?glass='+this.glass.id+'&branch=4'
    let model_parts_data=this.glass.model_parts
                
                for(let j=0;j<model_parts_data.length;j++) {
                
                    
                
                if(model_parts_data[j].render_type!="shadow" && model_parts_data[j].render_type!="hinge" && model_parts_data[j].render_type!="manchon"){
                    if(model_parts_data[j].type=='color') {
                        if(model_parts_data[j].render_slug=="frame" || model_parts_data[j].render_slug=="frame1")
                        {
                            if(model_parts_data[j].material=="plastic")
                            {
                                if(this.glass.default_color!=null && model_parts_data[j].render_slug!="frame1")
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'='+this.glass.default_color.id
                                }
                                else
                                {
                                    if(this.glass.id==29)
                                    {
                                        request+='&color_'+model_parts_data[j].render_slug+'=6'
                                    }
                                    else
                                    {
                                        request+='&color_'+model_parts_data[j].render_slug+'=10'
                                    }
                                }
                               

                                
                                
                            }
                            else 
                            {
                                if(this.glass.default_color!=null && model_parts_data[j].render_slug!="frame1")
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'='+this.glass.default_color.id
                                }
                                else
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'=19'
                                }
                            }
                            request+='&texture_style_'+model_parts_data[j].render_slug+'=0'
                        }
                        else if(model_parts_data[j].render_slug=="temples")
                        {
                            if(model_parts_data[j].material=="plastic")
                            {
                                if(this.glass.default_color!=null)
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'='+this.glass.default_color.id
                                }
                                else
                                {
                                    if(this.glass.id==29)
                                    {
                                        request+='&color_'+model_parts_data[j].render_slug+'=6'
                                    }
                                    else
                                    {
                                        if(model_parts_data[j].colors.length)
                                        {
                                            request+='&color_'+model_parts_data[j].render_slug+'='+model_parts_data[j].colors[0].id
                                        }
                                        else
                                        {
                                            request+='&color_'+model_parts_data[j].render_slug+'=10'
                                        }
                                    }
                                }
                               
                                
                            }
                            else {
                                if(model_parts_data[j].colors.length)
                                        {
                                            request+='&color_'+model_parts_data[j].render_slug+'='+model_parts_data[j].colors[0].id
                                        }
                                        else
                                        {
                                            request+='&color_'+model_parts_data[j].render_slug+'=20'
                                        }
                            }
                            request+='&texture_style_'+model_parts_data[j].render_slug+'=0'
                        }
                        else {
                        if(model_parts_data[j].material=="plastic")
                        {
                            request+='&color_'+model_parts_data[j].render_slug+'=10'
                           
                        }
                        else {
                            request+='&color_'+model_parts_data[j].render_slug+'=19'
                            
                        }
                        
                        }
                        request+='&texture_'+model_parts_data[j].render_slug+'=4'
                    
                    }
                    else if(model_parts_data[j].type=='texture_color') { 
                
                
                        if(model_parts_data[j].render_slug=="frame" || model_parts_data[j].render_slug=="frame1")
                        {

                            if(this.glass.default_color!=null && model_parts_data[j].render_slug!="frame1")
                            {
                                request+='&color_'+model_parts_data[j].render_slug+'='+this.glass.default_color.id
                            }
                            else
                            {

                                if(model_parts_data[j].material=="plastic")
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'=10'
                                }
                                else 
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'=19'
                                }
                            }
                            
                            request+='&texture_style_'+model_parts_data[j].render_slug+'=0'
                        }
                        else if(model_parts_data[j].render_slug=="temples")
                        {
                
                        
                            if(model_parts_data[j].material=="plastic")
                            {
                                if(this.glass.default_color!=null)
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'='+this.glass.default_color.id
                                }
                                else
                                {
                                request+='&color_'+model_parts_data[j].render_slug+'=10'
                                }
                            }
                            else {
                                if(model_parts_data[j].colors.length)
                                        {
                                            request+='&color_'+model_parts_data[j].render_slug+'='+model_parts_data[j].colors[0].id
                                        }
                                        else
                                        {
                                            request+='&color_'+model_parts_data[j].render_slug+'=20'
                                        }
                               
                            }
                        
                
                        request+='&texture_style_'+model_parts_data[j].render_slug+'=0'
                        }
                        else {
                        if(model_parts_data[j].material=="plastic")
                        {
                            request+='&color_'+model_parts_data[j].render_slug+'=10'
                        }
                        else {
                            request+='&color_'+model_parts_data[j].render_slug+'=19'
                        }
                        
                        }
                        
                        request+='&texture_'+model_parts_data[j].render_slug+'=4'
                
                    }
                    else if (model_parts_data[j].render_type=="lense")
                    {
                        // ici tint
                        let default_tint=4
                        if(this.selectedFilter=='optic')
                        {
                            default_tint=5
                        }
                        else 
                        {
                            if(this.glass.default_tint!=null)
                            {
                                default_tint=this.glass.default_tint.id
                            }
                               
                        }

                        
                        
                      
                        request+='&tint_'+model_parts_data[j].render_slug+'='+default_tint
                        
                    }
                    
                    
                    
                    }
                    else if (model_parts_data[j].render_type=="manchon")
                    {
                        request+='&color_'+model_parts_data[j].render_slug+'=24'
                   
                    }
                }
                
                
                let render_infos =  await APIRequest.list_renders('photos-layers-new',request)
                let render_infos_datas=render_infos
                
                if(this.glass.id==26)
                {
                 
                    this.photoDatas=render_infos_datas.scene1
                }
                else {
                    
                    this.photoDatas=render_infos_datas.scene2
                }
  }
    },
    async mounted() {
        await this.calculatePhotos()
        this.loading=false
    }
}
</script>

<style scoped>
.glass-item {
  margin-bottom: 20px;
}
.grey-image img
{

  width:100%;
  height:auto;
}
.grey-image .img .render-layer2
{
  position:absolute;
  width:100%;
  left:0;
  top:0;
  height:auto;
}
.grey-image .img 
{
  position:relative;
}
.grey-image {
    cursor: pointer;
  background: #F7F7F7;
  border: 0.5px solid #999999;
  border-radius: 10px;
  padding-top:50px;
  padding-bottom:50px;
}
.bottom-product
{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.right-bottom
{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title-glass
{
  font-size: 20px;
  font-weight: 500;
}
.price-glass
{
  font-size: 20px;
  font-weight: 500;
}
.button-glass
{
    cursor: pointer;
  margin-left: 10px;
  background: #000;
  padding: 5px 30px;
  border-radius: 10px;
  font-size:13px;
  color: white;
  font-weight: 400;
}
@media screen and (min-width:1024px) and (max-width:1400px)
{
    .title-glass
{
  font-size: 14px;
  font-weight: 500;
}
.price-glass
{
  font-size: 14px;
  font-weight: 500;
}
}
</style>