<template>
    <div>
        <div  class="slider" v-if="loading==false && isModalOpen==false">
            <img src="@/assets/images/fs.png" @click="openModal()" class="fs" />
            <div class="slider-wrapper" :style="{ transform: `translateX(-${currentIndex * 100}%)` }">
                <div class="slider-item" v-for="(image, index) in photoDatas" :key="index">
                    <RenderGlass :photoDatas="image" />
                </div>
            </div>
            <div class="navigation">
                <button @click="prevSlide" class="nav-arrow left-arrow"><img src="@/assets/images/left.png" /></button>
                <button @click="nextSlide" class="nav-arrow right-arrow"><img src="@/assets/images/right.png" /></button>
            </div>
            <div class="dots">
                <span v-for="(image, index) in photoDatas" :key="index" :class="{ active: index == calculateIndex()}" @click="goToSlide(index)"></span>
            </div>
        </div>

        <div v-if="isModalOpen" class="modal">
            <div class="modal-content">
                <span class="close" @click="closeModal"><img src="@/assets/images/cross.png"></span>
                <div class="slider-wrapper" :style="{ transform: `translateX(-${currentIndex * 100}%)` }">
                    <div class="slider-item" v-for="(image, index) in photoDatas" :key="index">
                        <RenderGlass :photoDatas="image" :modal="true" />
                    </div>
                </div>
                <div class="navigation">
                    <button @click="prevSlide" class="nav-arrow left-arrow"><img src="@/assets/images/left.png" /></button>
                    <button @click="nextSlide" class="nav-arrow right-arrow"><img src="@/assets/images/right.png" /></button>
                </div>
                <div class="dots">
                    <span v-for="(image, index) in photoDatas" :key="index" :class="{ active: index == calculateIndex()}" @click="goToSlide(index)"></span>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
import {APIRequest} from '@simb2s/senseye-sdk'
import RenderGlass from './RenderGlassNew.vue'
export default {
    components: {
   
        RenderGlass,
    
    },
    props: ['glass'],
    data() {
        return {
            isModalOpen: false,
            currentIndex: 0,
            loading: true,
            photoDatas:[],
            images: [
                'image1.jpg',
                'image2.jpg',
                'image3.jpg',
                // Ajoutez plus d'images ici
            ],
        };
    },
    methods: {
        openModal() {
           
            this.isModalOpen = true;
        },
        closeModal() {
            this.isModalOpen = false;
        },
        calculateIndex() {
            let realIndex=this.currentIndex+1
            let index='scene'+realIndex
          
            return index;
        },
        prevSlide() {
            if(this.currentIndex<=0)
            {
              
                let maxslide=this.glass.id==26 ? 2 : 4
               
                this.currentIndex=maxslide
            }
            else {
                this.currentIndex--
            }
            
        },
        nextSlide() {
            let maxslide=this.glass.id==26 ? 2 : 4
            if(this.currentIndex>=maxslide)
            {
                this.currentIndex=0
            }
            else {
                this.currentIndex++
            }
           
        },
        goToSlide(index) {
            console.log(index)
            let real_index=index.replace('scene','')
            real_index--
            this.currentIndex = real_index;
        },
        async calculatePhotos(){
    let request = '?glass='+this.glass.id+'&branch=4'
    let model_parts_data=this.glass.model_parts
                
                for(let j=0;j<model_parts_data.length;j++) {
                
                    
                
                if(model_parts_data[j].render_type!="shadow" && model_parts_data[j].render_type!="hinge" && model_parts_data[j].render_type!="manchon"){
                    if(model_parts_data[j].type=='color') {
                        if(model_parts_data[j].render_slug=="frame" || model_parts_data[j].render_slug=="frame1")
                        {
                            if(model_parts_data[j].material=="plastic")
                            {
                                if(this.glass.default_color!=null && model_parts_data[j].render_slug!="frame1")
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'='+this.glass.default_color.id
                                }
                                else
                                {
                                    if(this.glass.id==29)
                                    {
                                        request+='&color_'+model_parts_data[j].render_slug+'=6'
                                    }
                                    else
                                    {
                                        request+='&color_'+model_parts_data[j].render_slug+'=10'
                                    }
                                }
                               

                                
                                
                            }
                            else 
                            {
                                if(this.glass.default_color!=null && model_parts_data[j].render_slug!="frame1")
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'='+this.glass.default_color.id
                                }
                                else
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'=19'
                                }
                            }
                            request+='&texture_style_'+model_parts_data[j].render_slug+'=0'
                        }
                        else if(model_parts_data[j].render_slug=="temples")
                        {
                            if(model_parts_data[j].material=="plastic")
                            {
                                if(this.glass.default_color!=null)
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'='+this.glass.default_color.id
                                }
                                else
                                {
                                    if(this.glass.id==29)
                                    {
                                        request+='&color_'+model_parts_data[j].render_slug+'=6'
                                    }
                                    else
                                    {
                                        if(model_parts_data[j].colors.length)
                                        {
                                            request+='&color_'+model_parts_data[j].render_slug+'='+model_parts_data[j].colors[0].id
                                        }
                                        else
                                        {
                                            request+='&color_'+model_parts_data[j].render_slug+'=10'
                                        }
                                    }
                                }
                               
                                
                            }
                            else {
                                if(model_parts_data[j].colors.length)
                                        {
                                            request+='&color_'+model_parts_data[j].render_slug+'='+model_parts_data[j].colors[0].id
                                        }
                                        else
                                        {
                                            request+='&color_'+model_parts_data[j].render_slug+'=20'
                                        }
                            }
                            request+='&texture_style_'+model_parts_data[j].render_slug+'=0'
                        }
                        else {
                        if(model_parts_data[j].material=="plastic")
                        {
                            request+='&color_'+model_parts_data[j].render_slug+'=10'
                           
                        }
                        else {
                            request+='&color_'+model_parts_data[j].render_slug+'=19'
                            
                        }
                        
                        }
                        request+='&texture_'+model_parts_data[j].render_slug+'=4'
                    
                    }
                    else if(model_parts_data[j].type=='texture_color') { 
                
                
                        if(model_parts_data[j].render_slug=="frame" || model_parts_data[j].render_slug=="frame1")
                        {

                            if(this.glass.default_color!=null && model_parts_data[j].render_slug!="frame1")
                            {
                                request+='&color_'+model_parts_data[j].render_slug+'='+this.glass.default_color.id
                            }
                            else
                            {

                                if(model_parts_data[j].material=="plastic")
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'=10'
                                }
                                else 
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'=19'
                                }
                            }
                            
                            request+='&texture_style_'+model_parts_data[j].render_slug+'=0'
                        }
                        else if(model_parts_data[j].render_slug=="temples")
                        {
                
                        
                            if(model_parts_data[j].material=="plastic")
                            {
                                if(this.glass.default_color!=null)
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'='+this.glass.default_color.id
                                }
                                else
                                {
                                request+='&color_'+model_parts_data[j].render_slug+'=10'
                                }
                            }
                            else {
                                if(model_parts_data[j].colors.length)
                                        {
                                            request+='&color_'+model_parts_data[j].render_slug+'='+model_parts_data[j].colors[0].id
                                        }
                                        else
                                        {
                                            request+='&color_'+model_parts_data[j].render_slug+'=20'
                                        }
                               
                            }
                        
                
                        request+='&texture_style_'+model_parts_data[j].render_slug+'=0'
                        }
                        else {
                        if(model_parts_data[j].material=="plastic")
                        {
                            request+='&color_'+model_parts_data[j].render_slug+'=10'
                        }
                        else {
                            request+='&color_'+model_parts_data[j].render_slug+'=19'
                        }
                        
                        }
                        
                        request+='&texture_'+model_parts_data[j].render_slug+'=4'
                
                    }
                    else if (model_parts_data[j].render_type=="lense")
                    {
                        // ici tint
                        let default_tint=4
                        if(this.selectedFilter=='optic')
                        {
                            default_tint=5
                        }
                        else 
                        {
                            if(this.glass.default_tint!=null)
                            {
                                default_tint=this.glass.default_tint.id
                            }
                               
                        }

                        
                        
                      
                        request+='&tint_'+model_parts_data[j].render_slug+'='+default_tint
                        
                    }
                    
                    
                    
                    }
                    else if (model_parts_data[j].render_type=="manchon")
                    {
                        request+='&color_'+model_parts_data[j].render_slug+'=24'
                   
                    }
                }
                
                
                let render_infos =  await APIRequest.list_renders('photos-layers-new',request)
                let render_infos_datas=render_infos
                this.photoDatas=render_infos_datas
              
  }
    },
    async mounted() {
        
        await this.calculatePhotos()
        this.loading=false
    }
};
</script>

<style scoped>
.nav-arrow img 
{
    width:30px;
}
.fs {
    position:absolute;
    right:20px;
    top:-0;
    z-index:2;
    width:20px;
    cursor: pointer;
}
.slider {
    position: relative;
    width: 100%;
    overflow-x: hidden;
}

.slider-wrapper {
    display: flex;
    transition: transform 0.5s ease;
}

.slider-item {
    min-width: 100%;
    box-sizing: border-box;
}

.slider-item img {
    width: 100%;
    display: block;
}

.navigation {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
}

.nav-arrow {
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
}

.dots {
    position: absolute;
    bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.dots span {
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: #e3e3e3;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
}

.dots .active {
    background-color: #000000;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
}
.modal-content .dots
{ 
    bottom:10%;
}
.modal-content {
    position: relative;
    background-color: #f8f8f8;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 95%;
    overflow: hidden;
    height: 95%;
    
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    animation: modalopen 0.4s;
}
.close img {
    width: 40px;
  
}
.close {
    z-index: 2;
    right:0px;
    top:0;
    cursor: pointer;
    position: absolute;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

@keyframes modalopen {
    from { opacity: 0 }
    to { opacity: 1 }
}
</style>