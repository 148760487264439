var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"intro-container"},[_vm._m(0),(_vm.displayVoile)?_c('div',{staticClass:"voile"}):_vm._e(),(_vm.animFinished)?_c('div',{staticClass:"blur-bg"}):_vm._e(),_c('div',{staticClass:"image-follow-container",class:{animate:_vm.animFinished==true},style:({
    marginLeft: ("-" + (_vm.lastImage.x) + "px"),
    marginTop: ("-" + (_vm.lastImage.y) + "px"),
   
  }),on:{"mousemove":_vm.handleMouseMove}},[_c('div',{staticClass:"blur-content",class:{leaving:_vm.animFinished==true}},[_vm._v("Join The Eyewear Revolution.")]),_vm._l((_vm.photos),function(photo,index){return _c('div',{key:photo.id,staticClass:"image-follow",style:({
          left: ((photo.x) + "px"),
          top: ((photo.y) + "px"),
          transform: photo.transform_topleft,
        })},[_c('div',{staticClass:"photo-inner",style:({
  
          backgroundImage: ("url(" + (photo.url) + ")"),
          opacity: photo.opacity,
          transform: photo.transform,
        })},[_c('div',{staticClass:"blur"})])])}),_c('div',{staticClass:"button bottom-right",class:{leaving:_vm.animFinished==true},on:{"click":function($event){return _vm.finishAnimation()}}},[_vm._v("Start Creating")])],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("@/assets/images/logo_white.png")}})])}]

export { render, staticRenderFns }