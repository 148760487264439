<template>
    <GlassForm 
    v-if="loaded==true"
    :glass="glass" 
    :readOnly="false" 
    :glass_photos="glass.photos"
    :glass_textures="glass.textures"
    :glass_colors="glass.colors"
    :glass_tints="glass.tints"
    :glass_branchs="glass.branchs"
    :glass_glass_options="glass.glass_options"
    :glass_glass_types="glass.glass_types"
    :glass_refinements="glass.refinements"
    :glass_processings="glass.processings"
     />
</template>

<script>
import {BModal, BButton, VBModal, BCard, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest} from '@simb2s/senseye-sdk'
import { VueGoodTable } from 'vue-good-table'
import GlassForm from './GlassForm.vue'

export default {
  components: {
    BCard,
    BCardText,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BButton,
    GlassForm
  },
  data() {
    return {
      glass:null,
      loaded:false,

    }
  },
  watch: {
    '$route.params': {
      handler: function () {
        this.getGlass()
      },
      deep: true
    },
  },
  methods:{
    async getGlass()
    {
      this.loaded=false
      this.glass=await APIRequest.detail('glasses',this.$route.params.id)
      this.loaded=true
    }
  },
  async mounted()
  {
    this.getGlass()
  
  }
}
</script>
