
import {Auth} from "@simb2s/senseye-sdk"

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
  return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload);
}

export async function checkLogin()
{

  if(localStorage.token_senseye_auth && localStorage.refresh_token_senseye_auth)
  {
    
    let user=parseJwt(localStorage.token_senseye_auth);
    
    let user_data=await Auth.get_user(user.username)
    let is_admin=false
    let roles = user_data.roles
    if(roles!=null)
    {
      for(let i=0;i<roles.length;i++) {
        if(roles[i]=="ROLE_ADMIN" || roles[i]=="ROLE_CREATOR") {
          is_admin=true
        }
      }
    } 
    if(is_admin==true) {
      localStorage.id_user_senseye_auth=user_data.id;
      let Parameters={}
      Parameters.refresh_token=localStorage.refresh_token_senseye_auth
      let new_token = await Auth.refresh_token(Parameters)
      localStorage.token_senseye_auth=new_token.token
      localStorage.refresh_token_senseye_auth=new_token.refresh_token
  
      return user_data
    }    
    else
    {
      return {
        beta_access: null,
        biography: null,
        brand_logo: null,
        brand_name: null,
        created_at: null,
        customer_stripe_id: null,
        email: null,
        facebook: null,
        id: null,
        instagram: null,
        linkedin: null,
        main_user: null,
        name: null,
        password_changed: null,
        onboarding_finished:null,
        phone: null,
        roles: null,
        stl_files: null,
        stripe_id: null,
        surname: null,
        username: null,
        x: null
      }
    }
    
  }
  else
  {
    return {
      beta_access: null,
      biography: null,
      brand_logo: null,
      brand_name: null,
      created_at: null,
      customer_stripe_id: null,
      email: null,
      facebook: null,
      id: null,
      instagram: null,
      linkedin: null,
      main_user: null,
      name: null,
      password_changed: null,
      onboarding_finished:null,
      phone: null,
      roles: null,
      stl_files: null,
      stripe_id: null,
      surname: null,
      username: null,
      x: null
    }
  }
}

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return { name: 'access-control' }
  return { name: 'auth-login' }
}
