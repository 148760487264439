
<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >

    <div class="glass-names contenudesk contenuipad">
     
      <template v-for="(glass_item, index) in glasses">
        <div class="glass-name all"  v-if="glass_item.active && glass_item.id==glass.id">{{ glass_item.name }}</div>
        <div class="glass-name"  @click="$router.push({name:'editGlass', params: { id: glass_item.id }})" v-else-if="glass_item.active">{{ glass_item.name }}</div>
        
      </template>
    </div>


    <b-row class="row-big">
      
      <div :class="{'col-md-7':window.innerWidth > 768 && window.innerWidth <= 1024,'col-md-6':window.innerWidth <= 768 || window.innerWidth > 1024}">
        
        <b-card :title="glass.name" class="grey-col" >
          <a :href="'https://visages.com/collection/'+glass.id" target="_blank" class="custom-link">Personnaliser</a>
          
          <GlassRenders :glass="glass" />
       

          
            <!-- <div class="customfit-bottom" v-if="editingGlass">
              <div class="customfit">
                Tailles Uniques <img src='@/assets/images/cadenas1.svg' class="lock" />
              </div>
              <div class="customfit disabled">
                Sur-mesure <img src='@/assets/images/cadenas2.svg' class="lock" />
              </div>
            </div> -->
        </b-card>
      </div>
      
      <b-card title="Informations générales" class="col-md-6 mb-1" v-if="window.innerWidth<=760 || window.innerWidth>1024">
        <div class="round-element right clickable" :class="{grey:editingGlass==false,gray:editingGlass==true}" @click="editingGlass=!editingGlass">
            <img src='@/assets/images/edit2.svg' class="icon" width="70" />
          </div>
          <div class="round-element black">
            <img src='@/assets/images/doc.svg' class="icon" width="70" />
          </div>
        <br />
        <div class="contenumob"><br /><br /></div>
        <validation-observer ref="simpleRules">
       <b-form
          @submit.prevent
        >
        <b-row>
          <b-col
            md="10"
           
            class="mb-1"
          >
            <b-form-group
              label="Nom du produit"
              :class="{'form-group-plaintext':currentSuggest}"
              label-for="basicInput"
            >
            <validation-provider
              #default="{ errors }"
              name="Nom"
              rules="required"
            >
              <b-form-input
                id="basicInput"
                v-model="form.name"
                @input="editingGlass=true"
                placeholder="Nom du produit"
                :plaintext="currentSuggest==true"

                :state="errors.length > 0 ? false:null"
              />
              
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <img class="pending-icon" v-if="currentSuggest" src='@/assets/images/sablier2.svg' width="20" />
            <div  v-if="currentSuggest" class="current-suggest-popup">
              Votre modification est en cours de validation. Vous recevrez un email de confirmation sous peu.
            </div> 
          </b-form-group>
            <!-- <br />
            <b-form-group
              label="Nom de marque"
              label-for="basicInput"
            >
           
              <b-form-input
                id="basicInput"
                v-model="form.brand_name"
                :plaintext="readOnly"
                placeholder="Nom de la marque"
               
              />
             
            </b-form-group>
            -->
          

          </b-col>

          <b-col
            md="2"

            class="mb-1 price-top"
          >
          
          {{ glass.price }}€                                                                                                                                                                                                                                                           

          </b-col>
        
        </b-row>
        <!-- <b-row>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Prix"
              label-for="Prix"
            >
            <validation-provider
              #default="{ errors }"
              name="Prix"
              rules="required"
            >
              <b-form-input
                id="Prix"
                v-model="form.price"
                :plaintext="readOnly"
                type="number"
                step="0.01"
                placeholder="Prix"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>

      

         
        </b-row> -->
        <b-row>
          <b-col
            md="12"
            xl="12"
            class="mb-1"
            :class="{'form-group-plaintext':currentSuggest}"
          >
         
            <label for="textarea-default">Description du produit</label>
            <b-form-textarea
            @input="editingGlass=true"
              v-model="form.description"
              id="textarea-default"
              placeholder="Description produit"
              :plaintext="currentSuggest==true"
              rows="3"
            />
            <img class="pending-icon" src='@/assets/images/sablier2.svg' v-if="currentSuggest" width="20" />
            <div  v-if="currentSuggest" class="current-suggest-popup">
              Votre modification est en cours de validation. Vous recevrez un email de confirmation sous peu.
            </div> 
          </b-col>
        </b-row>
        
            
      </b-form>

    </validation-observer>

    <div class="sizes">
      <div class="label">Tailles disponibles</div>
      <div class="available-sizes">
        <div class="size active">Tailles standards</div>

        <div v-if="glass.custom_fit!=true" class="size disabled"><span class="txt">Sur-mesure</span> <img src='@/assets/images/cadenas2.svg' class="lock" /><div class="popup-size">Votre modèle ne dispose pas de sur-mesure</div></div>
        <div v-else class="size active"><span class="txt">Sur-mesure</span> <img src='@/assets/images/cadenas1.svg' class="lock" /></div>
        
      </div>
    </div>

    

<div class="buttons-bottom" v-if="editingGlass">
      <b-button 
      variant="outline-primary"
    @click="cancelEditing()"
    >
    Annuler
    </b-button>

    <b-button 
    @click.prevent="updateGlass"
    >
    Valider
    </b-button>
  </div>

  
        

      </b-card>

      <div class="col-md-5"  v-else>
       
       <b-card title="Nombre de visites" class="card-nb">
         <div class="round-element blue">
           <img src='@/assets/images/edit.svg' class="icon edit-blue" width="70" />
         </div>
         <div class="card-nb-col col-nb">

           <h2 class="big-nb"><span class="sign"><img src='@/assets/images/edit.svg' class="icon" width="70" /></span> {{ views_count }} </h2>
         </div>
         </b-card>
     </div>

    </b-row>

    <b-row class="row-align">
      <div class="col-md-4"  v-if="window.innerWidth<=760 || window.innerWidth>1024">
       
        <b-card title="Nombre de visites" class="card-nb">
          <div class="round-element blue">
            <img src='@/assets/images/edit.svg' class="icon edit-blue" width="70" />
          </div>
          <div class="card-nb-col col-nb">

            <h2 class="big-nb"><span class="sign"><img src='@/assets/images/edit.svg' class="icon" width="70" /></span> {{ views_count }} </h2>
          </div>
          </b-card>
      </div>


      <b-card title="Informations générales" class="col-md-12 mb-1" v-if="window.innerWidth>760 && window.innerWidth<1024">
        <div class="round-element right clickable" :class="{grey:editingGlass==false,gray:editingGlass==true}" @click="editingGlass=!editingGlass">
            <img src='@/assets/images/edit2.svg' class="icon" width="70" />
          </div>
          <div class="round-element black">
            <img src='@/assets/images/doc.svg' class="icon" width="70" />
          </div>
        <br />
        <div class="contenumob"><br /><br /></div>
        <validation-observer ref="simpleRules">
       <b-form
          @submit.prevent
        >
        <b-row>
          <b-col
            md="10"
           
            class="mb-1"
          >
            <b-form-group
              label="Nom du produit"
              :class="{'form-group-plaintext':currentSuggest}"
              label-for="basicInput"
            >
            <validation-provider
              #default="{ errors }"
              name="Nom"
              rules="required"
            >
              <b-form-input
                id="basicInput"
                v-model="form.name"
                @input="editingGlass=true"
                placeholder="Nom du produit"
                :plaintext="currentSuggest==true"

                :state="errors.length > 0 ? false:null"
              />
              
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <img class="pending-icon" v-if="currentSuggest" src='@/assets/images/sablier2.svg' width="20" />
            <div  v-if="currentSuggest" class="current-suggest-popup">
              Votre modification est en cours de validation. Vous recevrez un email de confirmation sous peu.
            </div> 
          </b-form-group>
            <!-- <br />
            <b-form-group
              label="Nom de marque"
              label-for="basicInput"
            >
           
              <b-form-input
                id="basicInput"
                v-model="form.brand_name"
                :plaintext="readOnly"
                placeholder="Nom de la marque"
               
              />
             
            </b-form-group>
            -->
          

          </b-col>

          <b-col
            md="2"

            class="mb-1 price-top"
          >
          
          {{ glass.price }}€                                                                                                                                                                                                                                                           

          </b-col>
        
        </b-row>
        <!-- <b-row>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Prix"
              label-for="Prix"
            >
            <validation-provider
              #default="{ errors }"
              name="Prix"
              rules="required"
            >
              <b-form-input
                id="Prix"
                v-model="form.price"
                :plaintext="readOnly"
                type="number"
                step="0.01"
                placeholder="Prix"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>

      

         
        </b-row> -->
        <b-row>
          <b-col
            md="12"
            xl="12"
            class="mb-1"
            :class="{'form-group-plaintext':currentSuggest}"
          >
         
            <label for="textarea-default">Description du produit</label>
            <b-form-textarea
            @input="editingGlass=true"
              v-model="form.description"
              id="textarea-default"
              placeholder="Description produit"
              :plaintext="currentSuggest==true"
              rows="3"
            />
            <img class="pending-icon" src='@/assets/images/sablier2.svg' v-if="currentSuggest" width="20" />
            <div  v-if="currentSuggest" class="current-suggest-popup">
              Votre modification est en cours de validation. Vous recevrez un email de confirmation sous peu.
            </div> 
          </b-col>
        </b-row>
        
            
      </b-form>

    </validation-observer>

    <div class="sizes">
      <div class="label">Tailles disponibles</div>
      <div class="available-sizes">
        <div class="size active">Tailles standards</div>

        <div v-if="glass.custom_fit!=true" class="size disabled"><span class="txt">Sur-mesure</span> <img src='@/assets/images/cadenas2.svg' class="lock" /><div class="popup-size">Votre modèle ne dispose pas de sur-mesure</div></div>
        <div v-else class="size active"><span class="txt">Sur-mesure</span> <img src='@/assets/images/cadenas1.svg' class="lock" /></div>
        
      </div>
    </div>

    

<div class="buttons-bottom" v-if="editingGlass">
      <b-button 
      variant="outline-primary"
    @click="cancelEditing()"
    >
    Annuler
    </b-button>

    <b-button 
    @click.prevent="updateGlass"
    >
    Valider
    </b-button>
  </div>

  
        

      </b-card>

     
        <b-card  :class="{'col-md-12':window.innerWidth > 768 && window.innerWidth <= 1024,'col-md-8':window.innerWidth <= 768 || window.innerWidth > 1024}" title="Montant généré">
         
        
          <div class="round-element green">
            <img src='@/assets/images/stats.svg' class="icon" width="70" />
          </div>

          <b-dropdown pill variant="outline-secondary" class="top-button top-right right" :text="currentDateFilter.text">
            <b-dropdown-item v-if="currentDateFilter.value!='week'" @click="selectDateFilter('week','This week')">This week</b-dropdown-item>
            <!-- <b-dropdown-item v-if="currentDateFilter.value!='month'" @click="selectDateFilter('month','This month')">This month</b-dropdown-item> -->
            <b-dropdown-item v-if="currentDateFilter.value!='year'" @click="selectDateFilter('year','This year')">This year</b-dropdown-item>
          </b-dropdown>


          <b-row>
            <div class="col-md-6 col-nb">
              <h2 class="big-nb contenudesk"><span class="sign">€</span> {{totalFiltered}} </h2>

            </div>
            <div class="col-md-6 green">
              <div v-if="comissions.length==0"><br /><br />Aucune vente pour ce produit.</div>
              <VueApexCharts v-else-if="graphLoading==false" height="150" :options="chartOptions" :series="series"></VueApexCharts>
              <h2 class="big-nb contenumob"><span class="sign">€</span> {{totalFiltered}} </h2>
            </div>
          </b-row>          
         
       

        </b-card>
     
    </b-row>

  
  <b-modal
          id="modal-danger"
          cancel-variant="outline-secondary"
          cancel-title="Non"
          ok-variant="danger"
          ok-title="Oui"
          @ok="deleteModelPart(selectedItem)"
          modal-class="modal-danger"
          centered
          title="Êtes-vous sûr ?"
        >
          <b-card-text>
            Attention, cette action est irréversible ! Êtes-vous sûr de vouloir supprimer ce model part ?
          </b-card-text>
        </b-modal>
  
  
      </b-overlay>

 

</template>

<script>
import { BButton,BFormInput, BDropdown,BDropdownItem,BRow, BForm, BCol,BOverlay, BFormGroup,BFormFile, BCard, BCardText, BMedia, BImg, BFormCheckbox, BFormTextarea, BSpinner} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import RenderGlass from './RenderGlass.vue'
import GlassRenders from './GlassRenders.vue'
import {APIRequest,Utils,Auth} from '@simb2s/senseye-sdk'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import VueApexCharts from 'vue-apexcharts'
import format from 'date-fns/format'
export default {
  name: 'GlassForm',
  components: {
    BButton,
    BDropdownItem,
    RenderGlass,
    GlassRenders,
    VueApexCharts,
    BDropdown,
    BCardText,
    BMedia, 
    BOverlay,
    BImg,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormFile,
    BCard,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
    BSpinner,
    draggable,
    ValidationProvider,
    ValidationObserver,
    BForm
  },
  directives: {
    Ripple,
  },
  data() {
      return {
        window:window,
        edit_suggests:null,
        currentSuggest:false,
        glasses:[],
        totalFiltered:0,
        totalComission:0,
        comissions:[],
        editingGlass:false,
        currentDateFilter:{text:'This week',value:'week'},
        views_count:0,
        date_begin:null, 
        dateFormatAPI: 'YYYY-MM-DD',
        dateFormat: 'D MMM YYYY',
        dateOne: this.getDateInit(),
        dateTwo: new Date() ,
        date_end:new Date(),
        series: [{
          name: 'Montant généré',
          data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2]
        }],
        chartOptions: {
          chart: {
            
            type: 'bar',
            toolbar: {
              show: false
            }
          },
          tooltip: {
            enabled: false, // Désactive la bulle au survol
          },
          states: {
            hover: {
                filter: {
                    type: 'none',
                }
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                }
            },
        },
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 3,
              dataLabels: {
                position: 'top', // top, center, bottom
              },
              colors: {
                ranges: [{
                  from: 0,
                  to: 100000,
                  color: '#E3E3E3'
                }],
                  
              },
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val + "€";
            },
            offsetY: -20,
            style: {
              fontWeight: 'normal',
              fontSize: '12px',
              colors: ["#000"]
            }
          },
              
          xaxis: {
            categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            position: 'bottom',
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            
            tooltip: {
              enabled: false,
            }
          },
          yaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
             
            }
          
          },
          grid: {
            show: false,
            borderColor: '#ffffff',
            strokeDashArray: 0,
            position: 'back',
            xaxis: {
              lines: {
                show: false
              }
            },
            yaxis: {
              lines: {
                show: false
              }
            }
          }
            
        },

        photoDatas:[],
           
        selectedItem:null,
          loading:true,
          
          initialForm:{
            name:"",
           
           brand_name:"",
          
           description:'',
           price:0,
           
          },
          initialForm:{
            name:"",
           
            brand_name:"",
           
            description:'',
            price:0,
            
          },
          form:{
            name:"",
           
            brand_name:"",
           
            description:'',
            price:0,
            
          },
          graphLoading:true,
         
      }
  },
  computed: {
   
  },
  filters: {
      
  },
  
  watch:{
     
  },
 
  props: [
    'glass', 
    'readOnly', 
    'glass_textures',
    'glass_colors',
    'glass_tints',
    'glass_branchs',
    'glass_glass_options',
    'glass_glass_types',
    'glass_refinements',
    'glass_processings',
    'glass_photos'
    ],
  methods:{
    cancelEditing()
    {
      this.form=this.initialForm
      this.editingGlass=false
    },
    selectDateFilter(value,text)
    {
      this.currentDateFilter.text=text
      this.currentDateFilter.value=value

      this.filterComissionsByDate()
    },
    filterComissionsByDate(){
      this.graphLoading=true
      this.totalFiltered=0
      let date_end=new Date()
      let date_begin
      if(this.currentDateFilter.value=='week')
      {
        let date = new Date()
        date.setDate(date.getDate() - 7)
        date_begin = date

        // Calculate all days of the week until today and put them in a table
        var daysOfWeek = []
        var valuesOfWeek = []
        for (let i = 0; i < 7; i++) {

          let tempDate = new Date(date_begin)
          tempDate.setDate(tempDate.getDate() + i)
          daysOfWeek.push(tempDate.toLocaleDateString('fr-FR', { weekday: 'short' }))
          valuesOfWeek.push(0)
        }
        
        this.chartOptions.xaxis.categories=daysOfWeek

        var datesOfWeek = []
        for (let i = 0; i < 7; i++) {
          let tempDate = new Date(date_begin)
          tempDate.setDate(tempDate.getDate() + i)
          datesOfWeek.push(tempDate.toLocaleDateString('us-US'))
        }

    
        
      

      }
      if(this.currentDateFilter.value=='month')
      {
        let date = new Date()
        date.setDate(date.getDate() - 30) 
        date_begin = date

        // Calculate all days of the month until today and put them in a table
        var daysOfMonth = []
        var valuesOfMonth = []
        for (let i = 0; i < 30; i++) {
          let tempDate = new Date(date_begin)
          tempDate.setDate(tempDate.getDate() + i)
          daysOfMonth.push(tempDate.toLocaleDateString('fr-FR', { day: 'numeric', month: 'short' }))
          valuesOfMonth.push(0)
        }
        
        this.chartOptions.xaxis.categories = daysOfMonth

        var datesOfMonth = []
        for (let i = 0; i < 30; i++) {
          let tempDate = new Date(date_begin)
          tempDate.setDate(tempDate.getDate() + i)
          datesOfMonth.push(tempDate.toLocaleDateString('us-US'))
        }


      }
      if(this.currentDateFilter.value=='year')
      {
        let date = new Date()
        date.setDate(date.getDate() - 365) 
        date_begin = date

        // Calculate all months of the year until today and put them in a table
        var monthsOfYear = []
        var valuesOfYear = []
        for (let i = 0; i < 12; i++) {
          let tempDate = new Date(date_begin)
          tempDate.setMonth(tempDate.getMonth() + i)
          monthsOfYear.push(tempDate.toLocaleDateString('fr-FR', { month: 'short', year: '2-digit' }))
          valuesOfYear.push(0)
        }
        
        this.chartOptions.xaxis.categories = monthsOfYear

        var datesOfYear = []
        for (let i = 0; i < 12; i++) {
          let tempDate = new Date(date_begin)
          tempDate.setMonth(tempDate.getMonth() + i)
          datesOfYear.push(tempDate.toLocaleDateString('us-US'))
        }
      }


    
          
      for(let i=0;i<this.comissions.length;i++)
      {
        if(this.comissions[i].date!=null)
        {
        
          let [d, m, y] = this.comissions[i].date.split(/-|\//); // splits "26-02-2012" or "26/02/2012"
          let date = new Date(y, m - 1, d);
          

          // On verifie si c'est compris entre les deux dates



          if(date.getTime()>=date_begin.getTime() && date.getTime()<=date_end.getTime())
          {


            // Si oui on ajoute le total de la comission
        
            

            if(this.currentDateFilter.value=='week')
            {
              for(let i=0;i<daysOfWeek.length;i++)
              {
                if(date.toLocaleDateString('fr-FR', { weekday: 'short' })==daysOfWeek[i])
                {
                  valuesOfWeek[i]+=this.comissions[i].amount
                  this.totalFiltered+=this.comissions[i].amount
                }
              }
              
            }
            if(this.currentDateFilter.value=='month')
            {
              for(let i=0;i<daysOfMonth.length;i++)
              {
                if(date.toLocaleDateString('fr-FR', { day: 'numeric', month: 'short' })==daysOfMonth[i])
                {
                  valuesOfMonth[i]+=this.comissions[i].amount
                  this.totalFiltered+=this.comissions[i].amount
                }
              }
            }
            if(this.currentDateFilter.value=='year')
            {
              for(let i=0;i<monthsOfYear.length;i++)
              {
                if(date.toLocaleDateString('fr-FR', { month: 'short', year: '2-digit' })==monthsOfYear[i])
                {
                  valuesOfYear[i]+=this.comissions[i].amount
                  this.totalFiltered+=this.comissions[i].amount
                }
              }
            }
          }

        }
      }

      if(this.currentDateFilter.value=='week')
        {
          this.series[0].data=valuesOfWeek
        }
        if(this.currentDateFilter.value=='month')
        {
          this.series[0].data=valuesOfMonth
        }
        if(this.currentDateFilter.value=='year')
        {
          this.series[0].data=valuesOfYear
        }

        setTimeout(() => {
          this.graphLoading=false
        }, 10);
      //this.blackGraphLoading=false
    },
    formatDates(dateOne, dateTwo) {
        let formattedDates = ''
        if (dateOne) {
          formattedDates = format(dateOne, this.dateFormat)
        }
        if (dateTwo) {
          formattedDates += ' - ' + format(dateTwo, this.dateFormat)
        }

        
        return formattedDates
      },
    getDateInit()
      {
        let date = new Date()
        date.setDate(date.getDate() - 30) 
        return date
      },
    calculatePhoto(layer_info) {
            let hue = layer_info.presets.hue_app ? layer_info.presets.hue_app : 100
            let saturation = layer_info.presets.saturation ? layer_info.presets.saturation : 100
            let brightness = layer_info.presets.brightness ? layer_info.presets.brightness : 100


            let url
            if(brightness==100 && hue==100 && saturation==100)
            {
            url = 'https://api.sens-eye.fr/render_parts/'+layer_info.render
            }
            else {
            url = 'https://api.sens-eye.fr/api/photo-single-test?photo='+layer_info.render+'&hue='+hue+'&saturation='+saturation+'&brightness='+brightness
            }

            //this.$emit('calculatePhotoEv',0)

            return url
            

        },
        
        getOpacityElement(layer) {
          if(this.photoDatas[layer].presets.opacity != null) {
                return this.photoDatas[layer].presets.opacity/100
            }
            else {
                return 1
            }
        },
  async calculatePhotos(){
    let request = '?glass='+this.glass.id+'&branch=4'
    let model_parts_data=this.glass.model_parts
                
                for(let j=0;j<model_parts_data.length;j++) {
                
                    
                
                if(model_parts_data[j].render_type!="shadow" && model_parts_data[j].render_type!="hinge" && model_parts_data[j].render_type!="manchon"){
                    if(model_parts_data[j].type=='color') {
                        if(model_parts_data[j].render_slug=="frame" || model_parts_data[j].render_slug=="frame1")
                        {
                            if(model_parts_data[j].material=="plastic")
                            {
                                if(this.glass.default_color!=null && model_parts_data[j].render_slug!="frame1")
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'='+this.glass.default_color.id
                                }
                                else
                                {
                                    if(this.glass.id==29)
                                    {
                                        request+='&color_'+model_parts_data[j].render_slug+'=6'
                                    }
                                    else
                                    {
                                        request+='&color_'+model_parts_data[j].render_slug+'=10'
                                    }
                                }
                               

                                
                                
                            }
                            else 
                            {
                                if(this.glass.default_color!=null && model_parts_data[j].render_slug!="frame1")
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'='+this.glass.default_color.id
                                }
                                else
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'=19'
                                }
                            }
                            request+='&texture_style_'+model_parts_data[j].render_slug+'=0'
                        }
                        else if(model_parts_data[j].render_slug=="temples")
                        {
                            if(model_parts_data[j].material=="plastic")
                            {
                                if(this.glass.default_color!=null)
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'='+this.glass.default_color.id
                                }
                                else
                                {
                                    if(this.glass.id==29)
                                    {
                                        request+='&color_'+model_parts_data[j].render_slug+'=6'
                                    }
                                    else
                                    {
                                        if(model_parts_data[j].colors.length)
                                        {
                                            request+='&color_'+model_parts_data[j].render_slug+'='+model_parts_data[j].colors[0].id
                                        }
                                        else
                                        {
                                            request+='&color_'+model_parts_data[j].render_slug+'=10'
                                        }
                                    }
                                }
                               
                                
                            }
                            else {
                                if(model_parts_data[j].colors.length)
                                        {
                                            request+='&color_'+model_parts_data[j].render_slug+'='+model_parts_data[j].colors[0].id
                                        }
                                        else
                                        {
                                            request+='&color_'+model_parts_data[j].render_slug+'=20'
                                        }
                            }
                            request+='&texture_style_'+model_parts_data[j].render_slug+'=0'
                        }
                        else {
                        if(model_parts_data[j].material=="plastic")
                        {
                            request+='&color_'+model_parts_data[j].render_slug+'=10'
                           
                        }
                        else {
                            request+='&color_'+model_parts_data[j].render_slug+'=19'
                            
                        }
                        
                        }
                        request+='&texture_'+model_parts_data[j].render_slug+'=4'
                    
                    }
                    else if(model_parts_data[j].type=='texture_color') { 
                
                
                        if(model_parts_data[j].render_slug=="frame" || model_parts_data[j].render_slug=="frame1")
                        {

                            if(this.glass.default_color!=null && model_parts_data[j].render_slug!="frame1")
                            {
                                request+='&color_'+model_parts_data[j].render_slug+'='+this.glass.default_color.id
                            }
                            else
                            {

                                if(model_parts_data[j].material=="plastic")
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'=10'
                                }
                                else 
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'=19'
                                }
                            }
                            
                            request+='&texture_style_'+model_parts_data[j].render_slug+'=0'
                        }
                        else if(model_parts_data[j].render_slug=="temples")
                        {
                
                        
                            if(model_parts_data[j].material=="plastic")
                            {
                                if(this.glass.default_color!=null)
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'='+this.glass.default_color.id
                                }
                                else
                                {
                                request+='&color_'+model_parts_data[j].render_slug+'=10'
                                }
                            }
                            else {
                                if(model_parts_data[j].colors.length)
                                        {
                                            request+='&color_'+model_parts_data[j].render_slug+'='+model_parts_data[j].colors[0].id
                                        }
                                        else
                                        {
                                            request+='&color_'+model_parts_data[j].render_slug+'=20'
                                        }
                               
                            }
                        
                
                        request+='&texture_style_'+model_parts_data[j].render_slug+'=0'
                        }
                        else {
                        if(model_parts_data[j].material=="plastic")
                        {
                            request+='&color_'+model_parts_data[j].render_slug+'=10'
                        }
                        else {
                            request+='&color_'+model_parts_data[j].render_slug+'=19'
                        }
                        
                        }
                        
                        request+='&texture_'+model_parts_data[j].render_slug+'=4'
                
                    }
                    else if (model_parts_data[j].render_type=="lense")
                    {
                        // ici tint
                        let default_tint=4
                        if(this.selectedFilter=='optic')
                        {
                            default_tint=5
                        }
                        else 
                        {
                            if(this.glass.default_tint!=null)
                            {
                                default_tint=this.glass.default_tint.id
                            }
                               
                        }

                        
                        
                      
                        request+='&tint_'+model_parts_data[j].render_slug+'='+default_tint
                        
                    }
                    
                    
                    
                    }
                    else if (model_parts_data[j].render_type=="manchon")
                    {
                        request+='&color_'+model_parts_data[j].render_slug+'=24'
                   
                    }
                }
                
                
                let render_infos =  await APIRequest.list_renders('photos-layers-new',request)
                let render_infos_datas=render_infos
                
                if(this.glass.id==26)
                {
                 
                    this.photoDatas=render_infos_datas.scene1
                }
                else {
                    
                    this.photoDatas=render_infos_datas.scene2
                }
  },

  async getEditSuggests(){
    let edits_suggest = await APIRequest.list('edit-suggests',null,null,1000)
    this.edit_suggests=edits_suggest[0]
    for(let i=0;i<this.edit_suggests.length;i++)
    {
      if(this.edit_suggests[i].glass.id==this.glass.id)
      {
        console.log('okk')
        this.currentSuggest=true
      }
    }
    

  },
     
      async updateGlass()
      {
         this.$refs.simpleRules.validate().then(async success => {
          if (success) {
         
          

          try {

            this.loading=true
        
            var datas={}
            datas.name=this.form.name
            datas.price=this.form.price
            datas.brand_name=this.form.brand_name
            datas.description=this.form.description
            datas.status="pending"
            datas.glass={id:this.glass.id}

            let edit_suggest = await APIRequest.create('edit-suggests',datas)
            
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'La suggestion a bien été envoyée !',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$router.push({name:'glasses'})
          } catch (error) {
            console.log("error")
            console.log(error)
            this.loading=false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Une erreur est survenue.',
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
            
          }
        }
         })
      },
      async getStats() {
        let datebegin=format(this.dateOne, this.dateFormatAPI)
        let dateend=format(this.dateTwo, this.dateFormatAPI)
        let stats=await APIRequest.get_stats(this.glass.id,datebegin,dateend)
        this.views_count=stats.data
      },
      async getComissions() {
        let comissions=await APIRequest.get_comissions_glass(this.glass.id)

        this.comissions=comissions.data
     
        this.totalComission=comissions.total
      }
      
      
  },
  async mounted(){

    let glasses=await APIRequest.list('glasses', null, null, 10000)
    this.glasses=glasses[0]

    if(this.glass!=null)
    {

     
      this.initialForm=this.glass
      this.form=this.glass
      
      
      this.edition=true
      console.log('yesy')
      console.log(this.glass.custom_fit)
    
      await this.getStats()

      await this.getComissions()

      await this.filterComissionsByDate()
      
    }
    
    await this.calculatePhotos()
    await this.getEditSuggests()
    this.loaded=true
    this.loading=false
  }

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
label
{
  font-size: 12px !important;
  font-weight: 500 !important;
  color:#000000 !important;
}
.custom-link:hover
{
  background-color: #fff;
  color:#000;
}
.custom-link
{
  border: 1px solid #000000;
border-radius: 10px;
padding:8px 50px;
font-size:14px;
background-color: #000000;
display: block;
float:left;
transition: all 0.3s;
color:#fff;
position:absolute;
top:15px;
left:150px;
z-index:3;
}
.card-nb-col
{
  height:90%;
}
.card-nb
{
  height:95%;
}
.price-top
{
  padding-top:33px;
  line-height: 1;
}
.buttons-bottom button
{
  width:49%;
}
img.lock
{
  display:inline-block;
  width:10px !important;
}
.buttons-bottom
{
  position:absolute;
  left:5%;
  width:90%;
  bottom:20px;
  display:flex;
  justify-content:space-between;
}
.top-right 
{
  margin-right:80px;
}
.grey-col .img img
{
  top:50% !important;
  position: absolute !important;
  transform: translateY(-50%);
  width:100%;
  height:auto;
}
.grey-col .img .render-layer2
{
  position:absolute;
  width:100%;
  left:0;
  top:0;
  height:auto;
}
.top-right
{
  left:200px;
}

.customfit-bottom .customfit.disabled
{
 opacity:0.5;
}
.customfit-bottom .customfit
{
padding:0 10px;
margin-right:10px;
margin-left:10px;
}
.customfit-bottom
{
  position:absolute;
  bottom:20px;
  left:0;
  width:100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
.sizes {
  position:relative;
  display:flex;
  align-items:center;
}
.row-align
{
  align-items: stretch !important;
}
.sizes .size.disabled:hover .popup-size
{
  
  display:block;
}
.sizes .popup-size
{
  display:none;
  font-size: 13px;
  background:#fff;
  color: #000000;
  transform:translateY(90%);
  padding:20px;
  position:absolute;
  bottom:0;
  z-index: 2;
  border-radius: 10px;
  border:0.5px solid #707070;
  max-width:200px;
}
.sizes .label 
{
  padding-right:15px;
  font-weight: 500;
  font-size: 12px !important;
}
.size.disabled img,.size.disabled .txt
{
  opacity:0.5;
}

.size.active {
  font-weight: 600;
  background:#fff;
}
.size {
  font-size:12px;
  
  border-radius:8px;
  padding:7px 15px;
}
.size:first-child
{
  margin-right:3px;
}
.available-sizes
{
  background-color:rgba(118,118,128,0.12);
  display:flex;
  padding:3px;
  border-radius: 8px;
}
@media screen and (max-width:768px)
{
  .col-md-10
  {
    width:75% !important;
  }
  .col-md-2
  {
    width:25% !important;
  }
  .buttons-bottom
  {
    position: relative;
    left:0;
    width:100%;
    bottom:auto;
    margin-top:20px;
  }
  .sizes .label
  {
    padding-right:5px !important;
  }
  .size
  {
    font-size:11px !important;
    padding: 5px 5px;
  }
  .img.lock
  {
    width:7px !important;
  }
}
@media screen and (min-width:768px) and (max-width:1024px)
{
 
  .buttons-bottom
  {
    position: relative;
    left:0;
    width:100%;
    bottom:auto;
    margin-top:20px;
  }
}
</style>