<template>
    <div v-if="isVisible" class="popup-overlay" @click="closePopup">
        <div class="popup-content" @click.stop>
            <button class="close-btn" @click="closePopup"><img src='@/assets/images/cross.png' /></button>
            <div class="header-popup">
                <div class="title-code">{{promotion.code}} 
                    <div @click="copyCode" class="cc">
                        <img v-if="copied==false" src='@/assets/images/copy.svg' />
                        <img v-else class="copied" src='@/assets/images/ok.svg' />
                    </div>
                </div>
                <div class="green-patch">-{{promotion.amount}}%</div>

            </div>
            <table class="table left-align">
                <tr>
                    <th>Produit concerné</th>
                    <td>{{ promotion.products[0].name }}</td>
                </tr>
               
                <tr>
                    <th>Limite d’utilisation par client</th>
                    <td> 
                        <span v-if="promotion.user_limit"> {{ promotion.user_limit }}</span>
                        <span v-else>Illimité</span>
                     </td>
                </tr>
                <tr>
                    <th>Total d’utilisations enregistrées</th>
                    <td>{{ promotion.use_count }}</td>
                </tr>
                <tr>
                    <th>Date de début</th>
                    <td>
                        <span v-if="promotion.begin_date"> {{ promotion.begin_date }}</span>
                        <span v-else>-</span>
                    </td>
                </tr>
                <tr>
                    <th>Date de fin</th>
                    
                    <td>
                        <span v-if="promotion.end_date"> {{ promotion.end_date }}</span>
                        <span v-else>-</span>
                       
                    </td>
                </tr>
                <tr>
                    <th>Plafond total d’utilisations</th>
                    <td>
                        <span v-if="promotion.usage_limit"> {{ promotion.usage_limit }}</span>
                        <span v-else>Illimité</span>
                    </td>
                </tr>
                <tr>
                    <th>Statut</th>
                    <td>  
                        <b-form-checkbox
                                v-model="promotion.active"
                                @change="activatePromotion(promotion.id,promotion.active)"
                                class="custom-control-primary"
                            > <template v-if="promotion.active">
                        
                            Actif
                            </template>
                            <template v-else>
                                Inactif
                            </template>
                                
                        </b-form-checkbox>
                       
                       </td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                </tr>
            </table>
            <center>
            <b-button
            v-b-modal.modal-danger 
				
				  variant="outline-dark"
				>
				  Supprimer
				</b-button>
            </center>
        </div>
        <b-modal
          id="modal-danger"
          cancel-variant="outline-secondary"
          cancel-title="Non"
          ok-variant="danger"
          ok-title="Oui"
          @ok="deletePromo"
          modal-class="modal-danger"
          centered
          title="Êtes-vous sûr ?"
        >
          <b-card-text>
            Attention, cette action est irréversible ! Êtes-vous sûr de vouloir supprimer cette promotion ?
          </b-card-text>
        </b-modal>
    </div>
</template>

<script>
import { BModal,BCardText,BButton,BFormInput, BForm, BRow, BOverlay,BFormDatepicker, BCol, BFormGroup,BFormFile, BCard, BFormCheckbox, BFormTextarea,BBadge} from 'bootstrap-vue'

export default {
    name: 'PopupPromotion',
    components: {
        BButton,
        BBadge,
        BModal,
        BFormCheckbox,
        BCardText
    },
    props:['promotion'],
    data() {
        return {
            isVisible: false,
            copied: false
        };
    },
    methods: {
        copyCode() {
            this.copied = true;
            setTimeout(() => {
                this.copied = false;
            }, 2000);
            navigator.clipboard.writeText(this.promotion.code);
        },
        activatePromotion(id,active) {
            this.$emit('activatePromotionEv', id,active);
        },
        openPopup() {
            this.isVisible = true;
        },
        closePopup() {
            this.isVisible = false;
        },
        deletePromo() {
            
            this.$emit('deletePromoEvent', this.promotion.id);
            this.closePopup();
        }
        
    }
};
</script>

<style scoped>
@keyframes copied {
    0% { opacity: 1; }
    100% { opacity: 0; }
    
}
.copied
{
    animation:copied 2s linear;
}
.green-patch {
    background: #E2F7E8;
    color: #29C770;
    padding: 5px 20px;
    border-radius: 10px;
    font-size: 22px;
}
.btn
{
    width: 100%;
}
.title-code .cc
{
    cursor: pointer;
    font-size:12px;
}
.title-code {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin-right:20px;
    font-size: 38px;
    padding-left:20px;
}
.header-popup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
}
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1031;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    position: relative;
}
.close-btn img {
    width:34px;
    height:auto;
}
.cc img {
    width:15px;
    margin-left:5px;
    height:auto;
}
.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
}
@media screen and (max-width: 768px) {
    .popup-content {
        width: 90%;
        padding-top:30px;
    }
    .title-code
    {
        font-size:23px;
    }
    .green-patch
    {
        font-size: 14px;
    }
}
</style>