<!-- src/components/GlassItem.vue -->
<template>
    <div :class="{render:modal!=true,render2:modal}">
        <b-overlay
      :show="loadedImages < photoDatas.length"
      spinner-variant="primary"
      spinner-type="grow"
      :opacity="1"
      spinner-small
      rounded="sm"
      style="height:100%"
    >
        
        <div :class="{img:modal!=true,img2:modal}">

            <img v-for="(layer, index_layer) in photoDatas" :key="index_layer" @load="imageLoaded" :src="calculatePhoto(layer)" :class="{'render-layer2':index_layer>0}"  :style="'opacity:'+getOpacityElement(index_layer)+';'" />
        </div>
       
    </b-overlay>
    </div>
</template>

<script>
import {APIRequest} from '@simb2s/senseye-sdk'
import {BModal, BTable, BTableSimple, BTableLite, BTd, BTbody, BTr, BTh, BThead, BPagination, BRow, BButton, VBModal,BOverlay, BCard, BCardText, BAvatar, BBadge, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'

export default {
    props: ['photoDatas',"modal"],
    components: {
        BOverlay,

    },
    data() {
        return {
           
            loading:true,
            loadedImages:0
        }
    },
    methods:{
        imageLoaded() {
            this.loadedImages++
        },
        calculatePhoto(layer_info) {
            let hue = layer_info.presets.hue_app ? layer_info.presets.hue_app : 100
            let saturation = layer_info.presets.saturation ? layer_info.presets.saturation : 100
            let brightness = layer_info.presets.brightness ? layer_info.presets.brightness : 100


            let url
            if(brightness==100 && hue==100 && saturation==100)
            {
            url = 'https://api.sens-eye.fr/render_parts/'+layer_info.render
            }
            else {
            url = 'https://api.sens-eye.fr/api/photo-single-test?photo='+layer_info.render+'&hue='+hue+'&saturation='+saturation+'&brightness='+brightness
            }

            //this.$emit('calculatePhotoEv',0)

            return url
            

        },
        
        getOpacityElement(layer) {
          if(this.photoDatas[layer].presets.opacity != null) {
                return this.photoDatas[layer].presets.opacity/100
            }
            else {
                return 1
            }
        },
  
  
    },
    updated(){
        
    },
beforeMount(){
   
    this.loadedImages=0

},
    async mounted() {
        
     
    }
}
</script>

<style scoped>
.glass-item {
  margin-bottom: 20px;
}
.render2 img
{
  margin-left:50%;
  transform: translateX(-50%);
  width:auto;
  height:100%;
}
.render img
{
 
  width:100%;
  height:auto;
}
.render .img .render-layer2
{
  position:absolute;
  width:100%;
  left:0;
  top:0;
  height:auto;
}
.render .img 
{
  
  position:relative;
}

.render2 .img2 .render-layer2
{
  position:absolute;
  width:auto;
  left:0;
  top:0;
  height:100%;
}
.render2 .img2 
{
  position:relative;
}
.render {
   position: relative;
   width:100%;
   height: 100%;
}
.render2 {
   position: relative;
   width:100%;
   height: 100%;
}
.bottom-product
{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.right-bottom
{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title-glass
{
  font-size: 20px;
  font-weight: 500;
}
.price-glass
{
  font-size: 20px;
  font-weight: 500;
}
.button-glass
{
    cursor: pointer;
  margin-left: 10px;
  background: #000;
  padding: 5px 30px;
  border-radius: 10px;
  font-size:13px;
  color: white;
  font-weight: 400;
}
.loader {
  text-align: center;
  font-size: 20px;
  color: #000;
  position:absolute;
  background:grey;
  width: 100%;
  height:100%;
  left:0;
  top:0;
}
</style>